import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Post from './Post'
const Article = () => (
    <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/article/:direction' component={Post}/>
        <Route path='/digest/:direction' component={Post}/>
        <Route path='/safekidsinfo/:direction' component={Post}/>
    </Switch>
)


export default Article
