import React from "react";
import { NavLink } from "react-router-dom";
import { DocItem } from "./DocItem";

export const Item = (props) =>
  props.files?.length ? <Lesson {...props} /> : <Group {...props} />;

export const Lesson = ({ label, time, description, files }) => {
  return (
    <div className="content__box">
      {label ? (
        <div className="content__box-title">
          {label} {time ? <small>{time}</small> : null}
        </div>
      ) : null}
      {description ? (
        <div className="content__box-description">{description}</div>
      ) : null}
      {files?.length ? (
        <div className="docs__list">
          <div className="row row__custom">
            {files.map(({ label, href }) => (
              <DocItem href={href}>{label}</DocItem>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const Group = ({ time, label, description, items }) => {
  return (
    <div className="content__box">
      {label ? (
        <div className="content__box-title">
          {label} {time ? <small>{time}</small> : null}
        </div>
      ) : null}
      {description ? (
        <div className="content__box-description">{description}</div>
      ) : null}
      {items?.map((item) =>
        item.files?.length ? <Lesson {...item} /> : <Group {...item} />
      )}
    </div>
  );
};
