import React from "react";
import { Link } from "react-router-dom";
import "url-search-params-polyfill";
import Footer from "./Footer";
import Header from "./Header";
import { Helmet } from "react-helmet";

import pr_fr_img from "../assets/img/pr-fr-img.png";
import shield from "../assets/img/shield.svg";
import target from "../assets/img/target.svg";
import radar from "../assets/img/radar.svg";
import st_img_1 from "../assets/img/st-1.jpg";
import st_img_2 from "../assets/img/st-2.jpg";
import st_img_3 from "../assets/img/st-3.jpg";
import rd_img from "../assets/img/rd-img.png";
import inst_img_1 from "../assets/img/instr-1.png";
import inst_img_2 from "../assets/img/instr-2.png";
import app_store from "../assets/img/app-store.svg";
import play_store from "../assets/img/play-store.svg";
import comp_icon_1 from "../assets/img/copm-icon-1.svg";
import browser from "../assets/img/browser.svg";
import schema from "../assets/img/schema.svg";
import target2 from "../assets/img/target2.svg";
import time from "../assets/img/time.svg";
import gear from "../assets/img/gear.svg";
import alarm from "../assets/img/alarm.svg";

import onlineContentFilter from "../assets/img/compare/online-content-filter.svg";
import youtubeSafeSearch from "../assets/img/compare/youtube-safe-search.svg";
import websiteFilters from "../assets/img/compare/website-filters.svg";
import screenTime from "../assets/img/compare/screen-time.svg";
import screenTimeScheduling from "../assets/img/compare/screen-time-scheduling.svg";
import gpsTracking from "../assets/img/compare/gps-tracking.svg";
import batteryTracker from "../assets/img/compare/battery-tracker.svg";
import vkActivityControl from "../assets/img/compare/vk-activity-control.png";
import yotubeSearchHistory from "../assets/img/compare/yotube-search-history.svg";
import realTimeAlerts from "../assets/img/compare/real-time-alerts.svg";

const compareTable = [
  [
    onlineContentFilter,
    "Контроль активности в интернете",
    "Защищайте детей от неподходящих сайтов и опасного контента*",
    true,
    true,
  ],

  [
    youtubeSafeSearch,
    "Безопасный поиск в YouTube",
    "Блокируйте потенциально опасные запросы ребенка в YouTube**",
    true,
    true,
  ],
  [
    websiteFilters,
    "Контроль использования приложений",
    "Контролируйте использование приложений в зависимости от проведенного в них времени, категории или возрастных ограничений***",
    true,
    true,
  ],

  [
    screenTime,
    "Контроль времени использования устройства",
    "Контролируйте время, которое ребенок проводит за смартфоном*",
    true,
    true,
  ],

  [
    screenTimeScheduling,
    "Использование устройства по расписанию",
    "Задавайте интервалы времени, когда ребенок не может использовать устройство*",
    false,
    true,
  ],

  [
    gpsTracking,
    "Геолокация",
    "Отслеживайте местонахождение детей и определите безопасный периметр, в котором им разрешено находиться",
    false,
    true,
  ],

  [
    batteryTracker,
    "Контроль уровня заряда батареи",
    "Следите за уровнем заряда батареи на устройстве ребенка, чтобы не потерять с ним связь",
    false,
    true,
  ],

  [
    vkActivityControl,
    "Контроль активности ВКонтакте",
    "Смотрите в отчетах My Kaspersky историю постов на страничке ребенка и изменения в списке его друзей",
    false,
    true,
  ],

  [
    yotubeSearchHistory,
    "История активности в YouTube",
    "Будьте в курсе того, что ваш ребенок ищет и смотрит на YouTube**",
    false,
    true,
  ],

  [
    realTimeAlerts,
    "Уведомления в режиме реального времени",
    "Получайте уведомления, если ребенок пытается открыть запрещенный сайт или приложение, покидает безопасный периметр и т.д.",
    false,
    true,
  ],
];

export default class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      md1: false,
      md2: false,
      tab: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  switchTab = (tab) => {
    this.setState({
      tab: tab,
    });
  };

  render() {
    return (
      <div className="page page__product">
        <Helmet>
          <title>Защита детей — Помощь в воспитании современного ребёнка</title>
        </Helmet>
        <Header topic={"soft"} />
        <div className="content">
          <div className="section sc__product">
            <div className="decor decor-13" />
            <div className="decor decor-14" />
            <div className="decor decor-12" />
            <div className="container">
              <div className="sc__content">
                <div className="row row__custom">
                  <div className="col-xl-4 col-lg-5 col-md-7 col-sm-12 col-12 col__custom">
                    <div className="product__title">
                      Kaspersky
                      <br />
                      Safe Kids
                    </div>
                    <div className="product__descr">
                      Помощь в&nbsp;воспитании современного ребёнка.
                    </div>
                    <div className="product__price-box d-flex align-items-end">
                      <div className="product__price">900₽</div>
                    </div>
                    <div className="actions__wrap">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 actions__item">
                          <a
                            target="_blank"
                            href="https://allsoft.kaspersky.ru/basket/add/3714076?clear=Y&spage=&referer6=-_-_Kaspersky-Safe-Kids"
                            className="btn__custom d-flex align-items-center justify-content-center"
                          >
                            Купить
                          </a>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 actions__item">
                          <a
                            target="_blank"
                            href="https://www.kaspersky.ru/safe-kids#installation"
                            className="btn__border btn__border-white d-flex align-items-center justify-content-center"
                          >
                            Попробовать
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="static__img">
              <img src={pr_fr_img} />
            </div>
          </div>
          <div className="section sc__advants">
            <div className="container">
              <div className="advants__list">
                <div className="row row__custom">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 col__custom advants__item">
                    <div className="advants__item-icon d-flex">
                      <img src={shield} />
                    </div>
                    <div className="advants__item-text">
                      Защищает от&nbsp;интернет-угроз
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 col__custom advants__item">
                    <div className="advants__item-icon d-flex">
                      <img src={target} />
                    </div>
                    <div className="advants__item-text">
                      Помогает контролировать время использования устройства
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 col__custom advants__item">
                    <div className="advants__item-icon d-flex">
                      <img src={radar} />
                    </div>
                    <div className="advants__item-text">
                      Позволяет узнать
                      <br />
                      местонахождение ребёнка
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section sc__common sc__info">
            <div className="container">
              <div className="info__list">
                <div className="info__item">
                  <div className="row row__custom align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom">
                      <div className="info__item-content">
                        <div className="info__item-title">
                          Безопасность ребёнка на&nbsp;любых устройствах
                        </div>
                        <div className="info__item-text">
                          <p>
                            Научите ребёнка проводить меньше времени
                            у&nbsp;экрана с&nbsp;помощью Kaspersky Safe Kids
                            и&nbsp;ограничьте время пользования устройствами.
                          </p>
                          <p>
                            Позаботьтесь о&nbsp;его безопасности
                            в&nbsp;сети&nbsp;— заблокируйте все нежелательные
                            приложения и&nbsp;сайты, получайте советы
                            профессионального психолога
                            об&nbsp;интернет-активности детей и&nbsp;обсуждайте
                            вопросы личной безопасности.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom info__item-img">
                      <div className="static__img">
                        <img src={st_img_1} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info__item">
                  <div className="row row__custom align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom">
                      <div className="info__item-content">
                        <div className="info__item-title">
                          Наблюдение за&nbsp;общением ребёнка с&nbsp;помощью
                          портала My&nbsp;Kaspersky
                        </div>
                        <div className="info__item-text">
                          <p>
                            Оставайтесь в&nbsp;курсе публикаций ребёнка
                            и&nbsp;изменений в&nbsp;списках друзей
                            во&nbsp;ВКонтакте, узнайте о&nbsp;подозрительных
                            группах, в&nbsp;которых состоит ребёнок ВКонтакте.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom info__item-img">
                      <div className="static__img">
                        <img src={st_img_2} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info__item">
                  <div className="row row__custom align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom">
                      <div className="info__item-content">
                        <div className="info__item-title">
                          Определяйте местоположение ребёнка на&nbsp;карте
                        </div>
                        <div className="info__item-text">
                          <p>
                            С&nbsp;помощью Kaspersky Safe Kids вы&nbsp;можете
                            в&nbsp;режиме реального времени узнать, где
                            находится ваш ребёнок.
                          </p>
                          <p>
                            Задайте периметр на&nbsp;карте, в&nbsp;пределах
                            которого ему следует быть в&nbsp;определённый период
                            времени, и, если ребёнок покинет безопасный
                            периметр, получите мгновенное уведомление.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom info__item-img">
                      <div className="static__img">
                        <img src={st_img_3} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section sc__redirect">
            <div className="container">
              <div className="row row__custom">
                <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-6 col-sm-12 col-12 col__custom">
                  <div className="sc__header">
                    <div className="sc__title">
                      Методические материалы для учителей
                    </div>
                    <div className="actions__wrap d-flex">
                      <Link
                        to="/metodic"
                        className="btn__custom btn__custom-green d-flex align-items-center justify-content-center"
                      >
                        Смотреть
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="static__img">
                <img src={rd_img} />
              </div>
            </div>
          </div>
          <div className="section sc__compare">
            <div className="container">
              <div className="sc__header">
                <div className="sc__title">Сравнить защиту</div>
              </div>
              <div className="compare__box">
                <div className="hidden-md-down">
                  <div className="compare__row compare__head">
                    <div className="row m-0 align-items-center">
                      <div className="col-4 p-0">
                        <div className="td">
                          <div className="tb__label">Критерий выбора</div>
                        </div>
                      </div>
                      <div className="col-4 p-0">
                        <div className="td">
                          <div className="compare__head-info d-flex align-items-center">
                            <div className="compare__head-info-icon">
                              <img src={comp_icon_1} />
                            </div>
                            <div className="compare__head-info-title">
                              Kaspersky
                              <br />
                              Safe Kids
                              <br />
                              <span>Бесплатная</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 p-0">
                        <div className="td">
                          <div className="compare__head-info d-flex align-items-center">
                            <div className="compare__head-info-icon">
                              <img src={comp_icon_1} />
                            </div>
                            <div className="compare__head-info-title">
                              Kaspersky
                              <br />
                              Safe Kids
                              <br />
                              <span>Премиум</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {compareTable.map(
                    ([icon, title, description, free, premium]) => (
                      <div className="compare__row compare__data">
                        <div className="row m-0 align-items-center">
                          <div className="col-4 p-0">
                            <div className="td">
                              <div className="compare__item d-flex">
                                <div className="compare__item-icon">
                                  <img src={icon} />
                                </div>
                                <div className="compare__item-content">
                                  <div className="compare__item-title">
                                    {title}
                                  </div>
                                  <div className="compare__item-descr">
                                    {description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 p-0">
                            <div className="td d-flex justify-content-center align-items-center">
                              <i className={`icon__${free ? "yes" : "no"}`} />
                            </div>
                          </div>
                          <div className="col-4 p-0">
                            <div className="td d-flex justify-content-center align-items-center">
                              <i
                                className={`icon__${premium ? "yes" : "no"}`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  <div className="compare__row compare__head">
                    <div className="row m-0 align-items-start">
                      <div className="col-4 p-0">
                        <div className="td"></div>
                      </div>
                      <div className="col-4 p-0">
                        <div className="td">
                          <div className="compare__head-info compare__head-info-height d-flex align-items-start">
                            <div className="compare__head-info-icon">
                              <img src={comp_icon_1} />
                            </div>
                            <div className="compare__head-info-title">
                              Kaspersky
                              <br />
                              Safe Kids
                              <br />
                              <span>Бесплатная</span>
                            </div>
                          </div>
                          <div className="actions__wrap d-flex">
                            <a
                              target="_blank"
                              href="https://www.kaspersky.ru/safe-kids#installation"
                              className="btn__border btn__border-green d-flex align-items-center justify-content-center"
                            >
                              Попробовать
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 p-0">
                        <div className="td">
                          <div className="compare__head-info  compare__head-info-height d-flex align-items-start">
                            <div className="compare__head-info-icon">
                              <img src={comp_icon_1} />
                            </div>
                            <div className="compare__head-info-title">
                              Kaspersky
                              <br />
                              Safe Kids
                              <br />
                              <div className="compare__head-info-price">
                                900₽
                              </div>
                              <span>Премиум</span>
                            </div>
                          </div>
                          <div className="actions__wrap d-flex">
                            <a
                              target="_blank"
                              href="https://allsoft.kaspersky.ru/basket/add/3714076?clear=Y&spage=&referer6=-_-_Kaspersky-Safe-Kids"
                              className="btn__custom btn__custom-green d-flex align-items-center justify-content-center"
                            >
                              Купить
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="recommend__decor">
                    <div className="recommend__title d-flex align-items-center justify-content-center">
                      Рекомендуем
                    </div>
                  </div>
                </div>
                <div className="hidden-lg-up">
                  <div className="compare__mb-head">
                    <div className="row row__custom">
                      <div className="col-6 col__custom">
                        <div className="compare__mb-head-box">
                          <div className="compare__mb-head-title">
                            Kaspersky
                            <br />
                            Safe Kids
                            <br />
                            <span>Бесплатная</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col__custom">
                        <div className="compare__mb-head-box compare__mb-head-main">
                          <div className="compare__mb-head-title">
                            Kaspersky
                            <br />
                            Safe Kids
                            <br />
                            <span>Премиум</span>
                          </div>
                          <div className="recommend__title d-flex align-items-center justify-content-center">
                            Рекомендуем
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="compare__mb-body">
                    {compareTable.map(
                      ([icon, title, description, free, premium]) => (
                        <div className="compare__mb-row">
                          <div className="compare__mb-data">
                            <div className="compare__item-title">{title}</div>
                            <div className="compare__item-descr">
                              {description}
                            </div>
                            <div className="compare__item-marks">
                              <div className="row row__custom">
                                <div className="col-6 col__custom d-flex justify-content-center">
                                  <i
                                    className={`icon__${free ? "yes" : "no"}`}
                                  />
                                </div>
                                <div className="col-6 col__custom d-flex justify-content-center">
                                  <i
                                    className={`icon__${
                                      premium ? "yes" : "no"
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}

                    {/* <div className="compare__mb-row">
                      <div className="compare__mb-data">
                        <div className="compare__item-title">
                          Контроль активности в&nbsp;интернете
                        </div>
                        <div className="compare__item-descr">
                          Защищает детей от&nbsp;поиска неподходящих сайтов
                          и&nbsp;информации.
                        </div>
                        <div className="compare__item-marks">
                          <div className="row row__custom">
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="compare__mb-row">
                      <div className="compare__mb-data">
                        <div className="compare__item-title">
                          Контроль использования программ
                        </div>
                        <div className="compare__item-descr">
                          Регулирует использование программ на&nbsp;компьютере
                          и&nbsp;мобильных устройствах.¹
                        </div>
                        <div className="compare__item-marks">
                          <div className="row row__custom">
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="compare__mb-row">
                      <div className="compare__mb-data">
                        <div className="compare__item-title">
                          Контроль времени использования устройств
                        </div>
                        <div className="compare__item-descr">
                          Ограничивает время, когда можно использовать смартфон
                          и&nbsp;планшет.²
                        </div>
                        <div className="compare__item-marks">
                          <div className="row row__custom">
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="compare__mb-row">
                      <div className="compare__mb-data">
                        <div className="compare__item-title">
                          Определение местонахождения ребёнка
                        </div>
                        <div className="compare__item-descr">
                          Позволяет вам знать, где ваш ребёнок,
                          и&nbsp;устанавливать для него безопасный периметр.
                        </div>
                        <div className="compare__item-marks">
                          <div className="row row__custom">
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="compare__mb-row">
                      <div className="compare__mb-data">
                        <div className="compare__item-title">
                          Контроль активности в&nbsp;социальных сетях
                        </div>
                        <div className="compare__item-descr">
                          Будьте в&nbsp;курсе публикаций ребёнка
                          во&nbsp;ВКонтакте с&nbsp;помощью портала&nbsp;
                          <a
                            target="_blank"
                            href="https://my.kaspersky.com/?returnUrl=https%3A%2F%2Fmy.kaspersky.com%2FMyKids"
                          >
                            My&nbsp;Kaspersky
                          </a>
                          .
                        </div>
                        <div className="compare__item-marks">
                          <div className="row row__custom">
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="compare__mb-row">
                      <div className="compare__mb-data">
                        <div className="compare__item-title">
                          Уведомления в&nbsp;реальном времени
                        </div>
                        <div className="compare__item-descr">
                          Отправляет мгновенные уведомления на&nbsp;мобильное
                          устройство родителя в&nbsp;тех случаях, когда ребёнок
                          ослушался предупреждения, вышел за&nbsp;пределы
                          безопасного периметра и&nbsp;т.&nbsp;д.
                        </div>
                        <div className="compare__item-marks">
                          <div className="row row__custom">
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                            <div className="col-6 col__custom d-flex justify-content-center">
                              <i className="icon__yes" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="compare__mb-head">
                    <div className="row row__custom">
                      <div className="col-6 col__custom">
                        <div className="compare__mb-head-box">
                          <div className="compare__mb-head-title">
                            Kaspersky
                            <br />
                            Safe Kids
                            <br />
                            <div className="compare__head-info-price"></div>
                            <span>Бесплатная</span>
                          </div>
                        </div>
                        <div className="actions__wrap d-flex">
                          <a
                            target="_blank"
                            href="https://www.kaspersky.ru/safe-kids#installation"
                            className="btn__border btn__border-green d-flex align-items-center justify-content-center"
                          >
                            Попробовать
                          </a>
                        </div>
                      </div>
                      <div className="col-6 col__custom">
                        <div className="compare__mb-head-box">
                          <div className="compare__mb-head-title">
                            Kaspersky
                            <br />
                            Safe Kids
                            <br />
                            <div className="compare__head-info-price">900₽</div>
                            <span>Премиум</span>
                          </div>
                        </div>
                        <div className="actions__wrap d-flex">
                          <a
                            target="_blank"
                            href="https://allsoft.kaspersky.ru/basket/add/3714076?clear=Y&spage=&referer6=-_-_Kaspersky-Safe-Kids"
                            className="btn__custom btn__custom-green d-flex align-items-center justify-content-center"
                          >
                            Купить
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="compare__hint">
                <div className="row row__custom">
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 col__custom">
                    <p>
                      * Полная функциональность доступна для устройств на базе
                      ОС Windows, Mac и Android. Фильтрация веб-контента
                      и контроль времени использования устройств доступны через
                      Семейный доступ для устройств с iOS 15.0+. Для устройств
                      iOS 12.0-14.0 фильтрация веб-контента доступна через
                      браузер Kaspersky Safe Kids. Контроль использования
                      устройства осуществляется с помощью предупреждения «Время
                      вышло», появляющегося на экране устройства ребенка, если
                      он превысил лимит времени.
                    </p>
                    <p>
                      ** Функция Безопасный поиск на YouTube совместима
                      с браузерами для устройств с ОС Windows и Android, а также
                      с браузером Kaspersky Safe Kids на базе iOS. Функция также
                      работает в официальном приложении YouTube для Android.
                      История просмотров на YouTube собирается по результатам
                      активности ребенка в браузере Chrome на Android
                      и поддерживаемых браузерах на ПК. Отчеты с историей
                      просмотров доступны в приложении Kaspersky Safe Kids,
                      установленном на Android-устройстве родителя.
                    </p>
                    <p>
                      *** В связи с ограничениями системы iOS использование
                      приложения возможно только после достижения определенного
                      возраста.
                    </p>
                  </div>
                </div>
              </div>
              <div className="loading__box d-flex align-items-center">
                <div className="loading__item d-flex">
                  <a
                    href="https://itunes.apple.com/ru/app/kaspersky-safe-kids-roditel/id967986300?mt=8"
                    target="_blank"
                    className="app__store-link"
                  >
                    <img src={app_store} />
                  </a>
                </div>
                <div className="loading__item d-flex">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.kaspersky.safekids"
                    target="_blank"
                    className="play__store-link"
                  >
                    <img src={play_store} />
                  </a>
                </div>
                <div className="loading__item d-flex">
                  <a
                    href="https://www.kaspersky.ru/downloads/thank-you/safe-kids-pc?ICID=SK_product_page_RUS_WIN_TYFD"
                    target="_blank"
                    className="link__icon d-flex align-items-center"
                  >
                    <span>Скачать для Windows</span>
                    <i className="icon__link">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.11312 13.7833L10.3506 15.0173L9.47294 15.8925C8.51506 16.8477 7.11892 17.2207 5.81043 16.8711C4.50194 16.5215 3.47989 15.5023 3.12929 14.1975C2.77868 12.8926 3.15277 11.5004 4.11065 10.5452L6.42758 8.25228C7.91025 6.78029 10.3072 6.78029 11.7899 8.25228L12.0795 8.54984L10.8684 9.74006L10.57 9.45126C10.1908 9.06912 9.67414 8.85409 9.13506 8.85409C8.59597 8.85409 8.07929 9.06912 7.70014 9.45126L5.3481 11.7967C4.55562 12.5918 4.55955 13.877 5.35688 14.6672C6.15421 15.4575 7.44301 15.4536 8.23549 14.6585L9.11312 13.7833ZM15.9411 4.10399C14.4584 2.632 12.0615 2.632 10.5788 4.10399L9.70119 4.97916L10.8684 6.22189L11.7461 5.34673C12.2665 4.8277 13.0252 4.625 13.7362 4.81498C14.4472 5.00495 15.0025 5.55875 15.193 6.26775C15.3835 6.97675 15.1803 7.73325 14.6598 8.25227L12.3077 10.5977C11.9286 10.9799 11.4119 11.1949 10.8728 11.1949C10.3337 11.1949 9.81705 10.9799 9.4379 10.5977L9.11318 10.2652L7.87573 11.4991L8.16535 11.7967C9.64802 13.2687 12.045 13.2687 13.5276 11.7967L15.8797 9.45125C17.3631 7.97998 17.3749 5.58972 15.906 4.10399H15.9411Z"
                          fill="#00A88E"
                        />
                      </svg>
                    </i>
                  </a>
                </div>
                <div className="loading__item d-flex">
                  <a
                    href="https://www.kaspersky.ru/downloads/thank-you/safe-kids-mac?ICID=SK_product_page_RUS_MAC_TYFD"
                    target="_blank"
                    className="link__icon d-flex align-items-center"
                  >
                    <span>Скачать для Mac</span>
                    <i className="icon__link">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.11312 13.7833L10.3506 15.0173L9.47294 15.8925C8.51506 16.8477 7.11892 17.2207 5.81043 16.8711C4.50194 16.5215 3.47989 15.5023 3.12929 14.1975C2.77868 12.8926 3.15277 11.5004 4.11065 10.5452L6.42758 8.25228C7.91025 6.78029 10.3072 6.78029 11.7899 8.25228L12.0795 8.54984L10.8684 9.74006L10.57 9.45126C10.1908 9.06912 9.67414 8.85409 9.13506 8.85409C8.59597 8.85409 8.07929 9.06912 7.70014 9.45126L5.3481 11.7967C4.55562 12.5918 4.55955 13.877 5.35688 14.6672C6.15421 15.4575 7.44301 15.4536 8.23549 14.6585L9.11312 13.7833ZM15.9411 4.10399C14.4584 2.632 12.0615 2.632 10.5788 4.10399L9.70119 4.97916L10.8684 6.22189L11.7461 5.34673C12.2665 4.8277 13.0252 4.625 13.7362 4.81498C14.4472 5.00495 15.0025 5.55875 15.193 6.26775C15.3835 6.97675 15.1803 7.73325 14.6598 8.25227L12.3077 10.5977C11.9286 10.9799 11.4119 11.1949 10.8728 11.1949C10.3337 11.1949 9.81705 10.9799 9.4379 10.5977L9.11318 10.2652L7.87573 11.4991L8.16535 11.7967C9.64802 13.2687 12.045 13.2687 13.5276 11.7967L15.8797 9.45125C17.3631 7.97998 17.3749 5.58972 15.906 4.10399H15.9411Z"
                          fill="#00A88E"
                        />
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
              <div className="instructions__list">
                <div className="row row__custom">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom instructions__item">
                    <div className="instructions__item-box d-flex justify-content-between">
                      <div className="instructions__item-content">
                        <div className="instructions__item-title">
                          Инструкция по&nbsp;скачиванию
                        </div>
                        <div className="instructions__item-descr">
                          Windows, Mac, iPhone &amp; iPad, Android
                        </div>
                      </div>
                      <div className="instructions__item-img">
                        <img src={inst_img_1} />
                      </div>
                      <div className="actions__wrap d-flex">
                        <div
                          onClick={() => {
                            this.setState({
                              md1: true,
                              tab: 0,
                            });
                          }}
                          className="link__download d-flex align-items-center"
                        >
                          <span>Смотреть</span>
                          <i className="icon__download">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.76942 7.27528L8.31905 5.82491L9.34398 4.79999L12.544 7.99999L9.34398 11.2L8.31905 10.1751L9.76938 8.72474H4V7.27528H9.76942Z"
                                fill="#00A88E"
                              />
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col__custom instructions__item">
                    <div className="instructions__item-box d-flex justify-content-between">
                      <div className="instructions__item-content">
                        <div className="instructions__item-title">
                          Системные требования
                        </div>
                        <div className="instructions__item-descr">
                          Windows, Mac, iPhone &amp; iPad, Android
                        </div>
                      </div>
                      <div className="instructions__item-img">
                        <img src={inst_img_2} />
                      </div>
                      <div className="actions__wrap d-flex">
                        <div
                          onClick={() => {
                            this.setState({
                              md2: true,
                              tab: 4,
                            });
                          }}
                          className="link__download d-flex align-items-center"
                        >
                          <span>Смотреть</span>
                          <i className="icon__download">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.76942 7.27528L8.31905 5.82491L9.34398 4.79999L12.544 7.99999L9.34398 11.2L8.31905 10.1751L9.76938 8.72474H4V7.27528H9.76942Z"
                                fill="#00A88E"
                              />
                            </svg>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div
          className={
            "modal__custom modal__1 " +
            (this.state.md1 === true ? "active" : "")
          }
        >
          <div className="modal__box">
            <div
              onClick={() => {
                this.setState({
                  md1: false,
                });
              }}
              className="close__modal"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.1626 11.9685L16.5612 18.3671L18.6825 16.2458L12.2839 9.84714L19.0095 3.12151L16.8882 1.00019L10.1626 7.72582L3.43675 1L1.31543 3.12132L8.04125 9.84714L1.64241 16.246L3.76373 18.3673L10.1626 11.9685Z"
                  fill="#CCCCCC"
                />
              </svg>
            </div>
            <div className="modal__box-title">Инструкция по скачиванию</div>
            <div className="modal__tabs">
              <div className="tabs__custom">
                <ul className="nav nav-tabs justify-content-center">
                  <li className="nav-item">
                    <div
                      onClick={() => {
                        this.switchTab(0);
                      }}
                      className={
                        "nav-link " + (this.state.tab === 0 ? "active" : "")
                      }
                    >
                      Windows
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      onClick={() => {
                        this.switchTab(1);
                      }}
                      className={
                        "nav-link " + (this.state.tab === 1 ? "active" : "")
                      }
                    >
                      Mac
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      onClick={() => {
                        this.switchTab(2);
                      }}
                      className={
                        "nav-link " + (this.state.tab === 2 ? "active" : "")
                      }
                    >
                      iPhone & iPad
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      onClick={() => {
                        this.switchTab(3);
                      }}
                      className={
                        "nav-link " + (this.state.tab === 3 ? "active" : "")
                      }
                    >
                      Android
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div
                  id="tab-1"
                  className={
                    "tab-pane fade " +
                    (this.state.tab === 0 ? "active show" : "")
                  }
                  role="tabpanel"
                >
                  <div className="tab__steps">
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        1
                      </div>
                      <div className="tab__step-text">
                        Откройте браузер на компьютере Windows вашего ребёнка
                        и зайдите на сайт 
                        <a href="https://kas.pr/kids" target="_blank">
                          kas.pr/kids
                        </a>
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        2
                      </div>
                      <div className="tab__step-text">
                        Нажмите кнопку «Скачать для Windows», установите
                        Kaspersky Safe Kids на компьютер ребёнка и следуйте
                        простым инструкциям на экране.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        3
                      </div>
                      <div className="tab__step-text">
                        Вы можете управлять настройками защиты, используя свою
                        учётную запись на{" "}
                        <a
                          href="https://my.kaspersky.com/?returnUrl=https%3a%2f%2fmy.kaspersky.com%2fMyKids"
                          target="_blank"
                        >
                          My Kaspersky
                        </a>
                        . Для входа введите имя пользователя и пароль, которые
                        вы использовали во время установки продукта.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        4
                      </div>
                      <div className="tab__step-text">
                        Вы также можете установить Kaspersky Safe Kids на свой
                        iPhone, iPad или устройство Android, чтобы удаленно
                        управлять настройками защиты, которые вы задали, а также
                        получать уведомления о подозрительных действиях.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab-2"
                  className={
                    "tab-pane fade " +
                    (this.state.tab === 1 ? "active show" : "")
                  }
                  role="tabpanel"
                >
                  <div className="tab__steps">
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        1
                      </div>
                      <div className="tab__step-text">
                        Откройте браузер на компьютере Mac вашего ребёнка
                        и зайдите на сайт 
                        <a href="https://kas.pr/kids" target="_blank">
                          kas.pr/kids
                        </a>
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        2
                      </div>
                      <div className="tab__step-text">
                        Нажмите кнопку «Скачать для Mac», установите Kaspersky
                        Safe Kids на компьютер ребенка и следуйте простым
                        инструкциям на экране.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        3
                      </div>
                      <div className="tab__step-text">
                        Вы можете управлять настройками защиты, используя свою
                        учётную запись на{" "}
                        <a
                          href="https://my.kaspersky.com/?returnUrl=https%3a%2f%2fmy.kaspersky.com%2fMyKids"
                          target="_blank"
                        >
                          My Kaspersky
                        </a>
                        . Для входа введите имя пользователя и пароль, которые
                        вы использовали во время установки продукта.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        4
                      </div>
                      <div className="tab__step-text">
                        Вы также можете установить Kaspersky Safe Kids на свой
                        iPhone, iPad или устройство Android, чтобы удаленно
                        управлять настройками защиты, которые вы задали, а также
                        получать уведомления о подозрительных действиях.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab-3"
                  className={
                    "tab-pane fade " +
                    (this.state.tab === 2 ? "active show" : "")
                  }
                  role="tabpanel"
                >
                  <div className="tab__steps">
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        1
                      </div>
                      <div className="tab__step-text">
                        Откройте App Store на устройстве вашего ребенка и при
                        помощи функции «Поиск» найдите продукт Kaspersky Safe
                        Kids.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        2
                      </div>
                      <div className="tab__step-text">
                        Начните загрузку и следуйте простым инструкциям на
                        экране.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        3
                      </div>
                      <div className="tab__step-text">
                        Вы можете управлять настройками защиты, используя свою
                        учётную запись на{" "}
                        <a
                          href="https://my.kaspersky.com/?returnUrl=https%3a%2f%2fmy.kaspersky.com%2fMyKids"
                          target="_blank"
                        >
                          My Kaspersky
                        </a>
                        . Для входа введите имя пользователя и пароль, которые
                        вы использовали во время установки продукта.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        4
                      </div>
                      <div className="tab__step-text">
                        Вы также можете установить Kaspersky Safe Kids на свой
                        iPhone, iPad или устройство Android, чтобы удаленно
                        управлять настройками защиты, которые вы задали, а также
                        получать уведомления о подозрительных действиях.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="tab-4"
                  className={
                    "tab-pane fade " +
                    (this.state.tab === 3 ? "active show" : "")
                  }
                  role="tabpanel"
                >
                  <div className="tab__steps">
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        1
                      </div>
                      <div className="tab__step-text">
                        Откройте магазин Google Play на Android-устройстве
                        вашего ребенка и при помощи функции «Поиск» найдите
                        продукт Kaspersky Safe Kids.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        2
                      </div>
                      <div className="tab__step-text">
                        Начните загрузку и следуйте простым инструкциям на
                        экране.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        3
                      </div>
                      <div className="tab__step-text">
                        Вы можете управлять настройками защиты, используя свою
                        учётную запись на{" "}
                        <a
                          href="https://my.kaspersky.com/?returnUrl=https%3a%2f%2fmy.kaspersky.com%2fMyKids"
                          target="_blank"
                        >
                          My Kaspersky
                        </a>
                        . Для входа введите имя пользователя и пароль, которые
                        вы использовали во время установки продукта.
                      </div>
                    </div>
                    <div className="tab__step d-flex align-items-start">
                      <div className="tab__step-numb d-flex align-items-center justify-content-center">
                        4
                      </div>
                      <div className="tab__step-text">
                        Вы также можете установить Kaspersky Safe Kids на свой
                        iPhone, iPad или устройство Android, чтобы удаленно
                        управлять настройками защиты, которые вы задали, а также
                        получать уведомления о подозрительных действиях.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            "modal__custom modal__2 " +
            (this.state.md2 === true ? "active" : "")
          }
        >
          <div className="modal__box">
            <div
              onClick={() => {
                this.setState({
                  md2: false,
                });
              }}
              className="close__modal"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.1626 11.9685L16.5612 18.3671L18.6825 16.2458L12.2839 9.84714L19.0095 3.12151L16.8882 1.00019L10.1626 7.72582L3.43675 1L1.31543 3.12132L8.04125 9.84714L1.64241 16.246L3.76373 18.3673L10.1626 11.9685Z"
                  fill="#CCCCCC"
                />
              </svg>
            </div>
            <div className="modal__box-title">Системные требования</div>
            <div className="modal__tabs">
              <div className="tabs__custom">
                <ul className="nav nav-tabs justify-content-center">
                  <li className="nav-item">
                    <div
                      onClick={() => {
                        this.switchTab(4);
                      }}
                      className={
                        "nav-link " + (this.state.tab === 4 ? "active" : "")
                      }
                    >
                      Windows
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      onClick={() => {
                        this.switchTab(5);
                      }}
                      className={
                        "nav-link " + (this.state.tab === 5 ? "active" : "")
                      }
                    >
                      Mac
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      onClick={() => {
                        this.switchTab(6);
                      }}
                      className={
                        "nav-link " + (this.state.tab === 6 ? "active" : "")
                      }
                    >
                      Android
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      onClick={() => {
                        this.switchTab(7);
                      }}
                      className={
                        "nav-link " + (this.state.tab === 7 ? "active" : "")
                      }
                    >
                      iPhone & iPad
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div
                  id="tab-5"
                  className={
                    "tab-pane fade " +
                    (this.state.tab === 4 ? "active show" : "")
                  }
                  role="tabpanel"
                >
                  <div className="tab__step-text">
                    <p>
                      <b>Для всех устройств</b>
                    </p>
                    <ul>
                      <li>Требуется интернет-соединение</li>
                    </ul>
                    <p>
                      <b>Компьютеры и ноутбуки Windows®</b>
                    </p>
                    <ul>
                      <li>200 Мб свободного места на жестком диске</li>
                      <li>Microsoft® Internet Explorer® 11 и выше</li>
                      <li>Microsoft .NET Framework 4 и выше</li>
                      <li>
                        Microsoft Windows 11 Домашняя / Профессиональная / Для
                        образовательных учреждений
                      </li>
                      <li>
                        Microsoft Windows 10 Домашняя / Профессиональная / Для
                        образовательных учреждений
                      </li>
                      <li>
                        Microsoft Windows 8 и 8.1 / Профессиональная / 8.1 с
                        обновлением
                      </li>
                      <li>
                        Microsoft Windows 7 Начальная / Домашняя базовая /
                        Домашняя расширенная / Профессиональная / Максимальная –
                        SP1 с KB4474419, KB4490628 обновления или выше
                      </li>
                      <li>Процессор: 1 ГГц и выше</li>
                      <li>
                        Оперативная память (RAM): 1 Гб (32 бит) или 2 Гб (64
                        бит)
                      </li>
                      <li>Минимальное разрешение экрана: 1024 x 768</li>
                    </ul>
                    <p>
                      Обратите внимание, что мы не поддерживаем бета-версии или
                      предварительные версии новых операционных систем. Продукт
                      поддерживает только официально выпущенные версии
                      операционных систем.
                    </p>
                  </div>
                </div>
                <div
                  id="tab-6"
                  className={
                    "tab-pane fade " +
                    (this.state.tab === 5 ? "active show" : "")
                  }
                  role="tabpanel"
                >
                  <div className="tab__step-text">
                    <p>
                      <b>Для всех устройств</b>
                    </p>
                    <ul>
                      <li>Требуется интернет-соединение</li>
                    </ul>
                    <p>
                      <b>Компьютеры и ноутбуки Mac®</b>
                    </p>
                    <ul>
                      <li>650 Мб свободного места на жестком диске</li>
                      <li>Оперативная память: 2 Гб</li>
                      <li>macOS 10.13 и выше</li>
                    </ul>
                    <p>
                      Обратите внимание, что мы не поддерживаем бета-версии или
                      предварительные версии новых операционных систем. Продукт
                      поддерживает только официально выпущенные версии
                      операционных систем.
                    </p>
                  </div>
                </div>
                <div
                  id="tab-7"
                  className={
                    "tab-pane fade " +
                    (this.state.tab === 6 ? "active show" : "")
                  }
                  role="tabpanel"
                >
                  <div className="tab__step-text">
                    <p>
                      <b>Для всех устройств</b>
                    </p>
                    <ul>
                      <li>Требуется интернет-соединение</li>
                    </ul>
                    <p>
                      <b>
                        Смартфоны и планшеты Android<sup>1</sup>
                      </b>
                    </p>
                    <ul>
                      <li>Android™ 4.4 и выше</li>
                      <li>Минимальное разрешение экрана: 320 x 480</li>
                    </ul>
                    <p>
                      Обратите внимание, что мы не поддерживаем бета-версии или
                      предварительные версии новых операционных систем. Продукт
                      поддерживает только официально выпущенные версии
                      операционных систем.
                    </p>
                    <small>
                      <sup>1</sup> Для получения более подробной информации о
                      настройке и использовании сервиса на устройствах Android™
                      зайдите на сайт{" "}
                      <a
                        target="_blank"
                        href="https://support.kaspersky.ru/safekids/android"
                      >
                        https://support.kaspersky.ru/safekids/android
                      </a>
                    </small>
                  </div>
                </div>
                <div
                  id="tab-8"
                  className={
                    "tab-pane fade " +
                    (this.state.tab === 7 ? "active show" : "")
                  }
                  role="tabpanel"
                >
                  <div className="tab__step-text">
                    <p>
                      <b>Для всех устройств</b>
                    </p>
                    <ul>
                      <li>Требуется интернет-соединение</li>
                    </ul>
                    <p>
                      <b>iPhone и iPad</b>
                    </p>
                    <ul>
                      <li>iOS 12.0 и выше</li>
                    </ul>
                    <p>
                      Обратите внимание, что мы не поддерживаем бета-версии или
                      предварительные версии новых операционных систем. Продукт
                      поддерживает только официально выпущенные версии
                      операционных систем.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
