import React from "react";
import { Link } from "react-router-dom";

import "../../assets/fonts/stylesheet.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/css/quiz.css";

export default class QuizResults extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const quizResult = this.props.quizResult;
    const quizResultTextObj = this.props.quizResultTextObj;
    let quizResultTitle = "";
    let quizResultText = "";

    if (quizResult < 4) {
      quizResultTitle = quizResultTextObj.low.title;
      quizResultText = quizResultTextObj.low.text;
    } else if (quizResult < 7) {
      quizResultTitle = quizResultTextObj.medium.title;
      quizResultText = quizResultTextObj.medium.text;
    } else if (quizResult >= 7) {
      quizResultTitle = quizResultTextObj.top.title;
      quizResultText = quizResultTextObj.top.text;
    }

    return (
      <div className={"quiz-results quiz-bg test-mode-" + this.props.test}>
        <div className="container d-flex align-items-center">
          <div className="quiz-container">
            <div className="quiz-header">
              <div className="quiz-header-title">Тест: {this.props.title}</div>
              <div className="quiz-header-number">РЕЗУЛЬТАТЫ</div>
            </div>
            <div className="quiz-result">
              {this.props.resultMode !== "simple" && (
                <div className="quiz-result-value">Правильных ответов</div>
              )}

              {this.props.resultMode !== "simple" && (
                <div className="quiz-result-value-number">
                  {quizResult} из {this.props.questionsLength}
                </div>
              )}

              {this.props.resultMode !== "simple" && (
                <div className="quiz-result-text">{quizResultTitle}</div>
              )}

              {this.props.resultMode === "simple" && (
                <div className="quiz-result-text">
                  Ваш результат — {quizResult} из {this.props.questionsLength}
                </div>
              )}
            </div>
            <div className="quiz-results-description">
              <p dangerouslySetInnerHTML={{ __html: quizResultText }} />

              <div className="quiz-results-btns">
                <button
                  className="btn__custom quiz-button"
                  onClick={this.props.reStart}
                >
                  Пройти еще раз
                </button>
                <Link
                  to={"/"}
                  className="btn__custom btn__custom-green quiz-button"
                >
                  Вернуться на главную
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
