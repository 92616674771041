import React from "react";
import pr_fr_img from "../../assets/img/pr-fr-img.png";

const MetodicHeader = () => (
  <div className="section sc__product">
    <div className="decor decor-13" />
    <div className="decor decor-14" />
    <div className="decor decor-12" />
    <div className="container">
      <div className="sc__content">
        <div className="row row__custom">
          <div className="col-xl-4 col-lg-5 col-md-7 col-sm-12 col-12 col__custom">
            <div className="product__title">
              Kaspersky
              <br />
              Safe Kids
            </div>
            <div className="product__descr">
              Помощь в&nbsp;воспитании современного ребёнка.
            </div>
            <div className="actions__wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 actions__item">
                  <a
                    target="_blank"
                    href="https://allsoft.kaspersky.ru/basket/add/3714076?clear=Y&spage=&referer6=-_-_Kaspersky-Safe-Kids"
                    className="btn__custom d-flex align-items-center justify-content-center"
                  >
                    Купить
                  </a>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 actions__item">
                  <a
                    target="_blank"
                    href="https://www.kaspersky.ru/safe-kids#installation"
                    className="btn__border btn__border-white d-flex align-items-center justify-content-center"
                  >
                    Попробовать
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="static__img">
      <img src={pr_fr_img} />
    </div>
  </div>
);

export default MetodicHeader;
