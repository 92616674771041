import React from "react";
import "url-search-params-polyfill";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
import MetodicHeader from "./Header";
import { NavTabs } from "./NavTabs";
// import juniorData from "./junior.json";
// import middleData from "./middle.json";
// import is7Data from "./information-security-7th-grade.json";
import { Item, Lesson, Group } from "./Lesson";
import { data } from "./data";

const Items = ({ items }) => items.map((item) => <Item {...item} />);

// const ClassesJuniorContent = () => {
//   return juniorData.map((item) => <Lesson {...item} />);
// };

// const ClassesMiddleContent = ({ group }) => {
//   switch (group) {
//     case "is-7":
//       return middleIs7Data.map((item) => <LessonsGroup {...item} />);
//     default:
//       return middleData.map((item) => <Lesson {...item} />);
//   }
// };

const Content = ({ classes }) => {
  console.log(classes, data[classes]);
  if (classes in data) {
    return <Items items={data[classes]} />;
  }

  return null;

  // switch (classes) {
  //   case "junior":
  //     return <Items items={juniorData} />;
  //   case "middle":
  //     return <Items items={middleData} />;
  //   case "middle":
  //     return <Items items={middleIs7Data} />;
  //   // return <ClassesMiddleContent {...{ group }} />;
  //   default:
  //     return null;
  // }
};
const Metodic = (props) => {
  const { classes, group } = props.match.params;
  return (
    <div className="page page__product">
      <Helmet>
        <title>Защита детей — Методические материалы для учителей</title>
      </Helmet>
      <Header topic="metodic" />
      <div className="content">
        <MetodicHeader />
        <div className="section sc__common sc__met">
          <div className="container">
            <div className="sc__header">
              <div className="sc__title">
                Методические материалы для учителей
              </div>
            </div>
            <div className="sc__content">
              <div className="tabs__custom">
                <NavTabs active={classes} />
              </div>
              <div className="tab-content">
                <Content {...{ classes }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Metodic;
