import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import QuizPreview from "./QuizPreview"
import {Helmet} from "react-helmet"
import QuizQuestionsList from "./QuizQuestionsList"
import QuizModal from "./QuizModal"
import QuizResults from "./QuizResults"

import "../../assets/fonts/stylesheet.css"
import "../../assets/css/bootstrap.min.css"
import "../../assets/css/style.css"
import "../../assets/css/quiz.css"
import testNubGuruData from "../../assets/json/test-nub-guru-data"

export default class QuizNubGuru extends React.Component {
    constructor(props){
        super(props);
        this.handleStartBtnClick = this.handleStartBtnClick.bind(this);
        this.handleAnswerBtnClick = this.handleAnswerBtnClick.bind(this);
        this.handleNextQuestion = this.handleNextQuestion.bind(this);
        this.handleRestart = this.handleRestart.bind(this);

        this.state = {
            mode: 'parent',
            theme: 'light',
            isFooterSubscribe: false,
            isQuizStarted: false,
            isQuizCompleted: false,
            isShowModal: false,
            answerType: false,
            questions: {},
            resultsText : {},
            currentQuestion: 0,
            questionsLength: 0,
            resultArray: [],
            quizPoints: 0,
            selectedAnswer: false,
            modal: false,
            title: '',
            resultMode: '',
            test: 0
        }
    }

    componentDidMount() {
        /*const questionsJson = testNubGuruData;
        const questions = questionsJson.questions;
        const resultsText = questionsJson.results[0];
        const questionsLength =  questions.length;
        const resultArray = this.initPagination(questionsLength);

        this.setState({
            questions,
            resultsText,
            questionsLength,
            resultArray
        });*/
    }

    initPagination(arrLength) {
        let resultArray = [];
        for (let i = 0; i < arrLength; i++) {
            resultArray.push(0) // for creating empty array
        }
        resultArray[0] = 'quiz-question-pagination-item-current';

        return resultArray
    }

    handleStartBtnClick(json, modal, title, resultMode, test) {
        const questionsJson = require('../../assets/json/' + json + '.json');
        const questions = questionsJson.questions;
        const resultsText = questionsJson.results[0];
        const questionsLength =  questions.length;
        const resultArray = this.initPagination(questionsLength);

        this.setState({
            questions,
            resultsText,
            questionsLength,
            resultArray,
            title: title,
            modal: modal,
            resultMode: resultMode,
            test: test
        }, ()=>{
            this.setState({
                isQuizStarted: true,
                currentQuestion: 1
            });
        });

    }

    handleAnswerBtnClick(isCorrect) {
        const currentPoints = this.state.quizPoints;
        if(this.state.modal === true){
            this.setState({
                quizPoints: isCorrect ? currentPoints + 1 : currentPoints,
                answerType: isCorrect,
                isShowModal: true,
                selectedAnswer: true
            });
        }else{
            this.setState({
                quizPoints: isCorrect ? currentPoints + 1 : currentPoints,
                answerType: isCorrect
            }, ()=>{
                this.handleNextQuestion()
            });

        }
    }

    handleNextQuestion() {
        const currentQuestion = this.state.currentQuestion;
        const isLastQuestion = currentQuestion === this.state.questionsLength;
        const paginationItem = this.state.answerType ? 'quiz-question-pagination-item-correct' : 'quiz-question-pagination-item-wrong';
        let currentPaginationArray = [...this.state.resultArray];
        currentPaginationArray[currentQuestion - 1] = paginationItem;
        currentPaginationArray[currentQuestion] = 'quiz-question-pagination-item-current';

        if (isLastQuestion) {
            this.setState({
                isQuizCompleted: true,
                isShowModal: false
            });
        } else {
            this.setState({
                isShowModal: false,
                selectedAnswer: false,
                resultArray: currentPaginationArray
            });

            setTimeout(() => {
                this.setState({
                    currentQuestion: currentQuestion + 1,
                },);
            }, 100)
        }
    }

    updateMode = (mode) =>{
        this.setState({
            mode: mode
        }, ()=>{
            localStorage.setItem('mode', mode);
        })
    }

    handleRestart() {
        const resultArray = this.initPagination(this.state.questionsLength);

        this.setState({
            isQuizStarted: false,
            isQuizCompleted: false,
            isShowModal: false,
            answerType: false,
            currentQuestion: 0,
            resultArray,
            quizPoints: 0,
            selectedAnswer: false
        });
    }

    render() {
        const isLastQuestion = this.state.currentQuestion === this.state.questionsLength;

        const modalComponent =
            Boolean(this.state.currentQuestion)
                ? <QuizModal
                    isShowModal={this.state.isShowModal}
                    answerType={this.state.answerType}
                    questionsList={this.state.questions}
                    currentQuestion={this.state.currentQuestion}
                    nextQuestion={this.handleNextQuestion}
                    isLastQuestion={isLastQuestion}
                />
                : <div />;

        let currentComponent =
            <QuizPreview mode={this.state.mode} onStartBtnClick={this.handleStartBtnClick} />;

        if (this.state.isQuizCompleted) {
            currentComponent =
                <QuizResults
                    quizResultTextObj={this.state.resultsText}
                    quizResult={this.state.quizPoints}
                    questionsLength={this.state.questionsLength}
                    resultMode={this.state.resultMode}
                    title={this.state.title}
                    test={this.state.test}
                    reStart={this.handleRestart}
                />
        } else if (this.state.isQuizStarted) {
            currentComponent =
                <QuizQuestionsList
                    questionsList={this.state.questions}
                    currentQuestion={this.state.currentQuestion}
                    questionsLength={this.state.questionsLength}
                    selectedAnswer={this.state.selectedAnswer}
                    answerType={this.state.answerType}
                    resultArray={this.state.resultArray}
                    title={this.state.title}
                    onAnswerBtnClick={this.handleAnswerBtnClick}
                />
        }

        return (
            <div className="page quiz-page">
                <Helmet>
                    <title>Защита детей — Помощь в воспитании современного ребёнка</title>
                </Helmet>
                <Header
                    theme={this.state.theme}
                    page={'home'}
                    topic={'interactive'}
                    updateMode={this.updateMode}
                />
                <div className="content quiz-content">
                    {currentComponent}
                </div>
                <Footer isFooterSubscribe={this.state.isFooterSubscribe} />
                {modalComponent}
            </div>
        )
    }
}
