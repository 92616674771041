import React from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"
import 'url-search-params-polyfill';
import Footer from './Footer'
import Header from './Header'
import pr_fr_img from '../assets/img/pr-fr-img.png';
import icon_time from '../assets/img/icon-time.svg';
import icon_target from '../assets/img/icon-target.svg';
import icon_gear from '../assets/img/icon-gear.svg';

import {
    isSafari,
    isMacOs
} from "react-device-detect";

async function WebpIsSupported() {
    // If the browser doesn't has the method createImageBitmap, you can't display webp format
    if (!window.self.createImageBitmap) return false;

    // Base64 representation of a white point image
    const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

    // Retrieve the Image in Blob Format
    const blob = await fetch(webpData).then(r => r.blob());

    // If the createImageBitmap method succeeds, return true, otherwise false
    return createImageBitmap(blob).then(() => true, () => false);
}

export default class Metodic extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            webp: false,
            slug: props.match.params.direction,
            contents: [],
            html: '',
            title_color: '#fff',
            introduction: '',
            meta: {},
            mode: 'parent',
            poster: {},
            related_article: {
                introduction: '',
                poster: {}
            },
            title: '',
            rl_post_opt: '',
            rl_post_gen: '',
            rl_title_color: '#ffffff',
            scrollUp: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getArticleBySlug();
        if(isSafari === true && isMacOs === true){
            this.setState({
                webp: false
            });
        }else{
            this.setState({
                webp: WebpIsSupported()
            });
        }

        if(localStorage.getItem('mode') !== undefined && localStorage.getItem('mode') !== null && localStorage.getItem('mode') !== ''){
            this.setState({
                mode: localStorage.getItem('mode')
            });
        }

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > window.outerHeight){
            this.setState({
                scrollUp: true
            });
        }else{
            this.setState({
                scrollUp: false
            });
        }
    }

    getArticleBySlug = async() => {
        await fetch('/backend/api/getArticleBySlug?type=article&slug=' + this.state.slug, {
            method: "GET"
        }).then((response) => response.json()).then((response) => {
            if(response.error === 0){
                this.setState({
                    contents: response.contents,
                    html: response.html,
                    introduction: response.introduction,
                    meta: response.meta,
                    poster: response.poster,
                    related_article: response.related_article,
                    title: response.title,
                    title_color: response.title_color,
                    rl_title_color: response.related_article.title_color,
                });

                if(response.related_article.poster !== undefined){
                    this.setState({
                        rl_post_opt: response.related_article.poster.optimized,
                        rl_post_gen: response.related_article.poster.general
                    });
                }
            }
        });
    }

    renderPostContent = () => {
        if(this.state.contents.blocks !== undefined && this.state.contents.length !== 0){
            return(
                this.state.contents.blocks.map((item, index) => (
                    <div key={index} className="article__text-box">
                        <div className="article__text">

                        </div>
                    </div>
                ))
            )
        }

    }

    renderRelativeArticle = () => {
        if(this.state.related_article.title !== undefined){
            return(
                <div className="section sc__other">
                    <div className="sc__bg" style={{ backgroundImage: 'url(' + ((this.state.webp === true) ? this.state.rl_post_opt : this.state.rl_post_gen) + ')' }} />
                    <div className="decor decor-10" />
                    <div className="container">
                        <div className="sc__content">
                            <div className="row row__custom">
                                <div className="col-xl-6 col-lg-7 col-md-10 col-sm-12 col-12 col__custom">
                                    <div className="sc__header">
                                        <div className="sc__label hidden-md-up">Следующая статья</div>
                                        <div className="sc__title">
                                            <a href={'/article/' + this.state.related_article.slug} style={{color: this.state.rl_title_color}} dangerouslySetInnerHTML={{__html: this.state.related_article.title}}/>
                                        </div>
                                    </div>
                                    <div className="sc__descr" style={{color: this.state.rl_title_color}}  dangerouslySetInnerHTML={{__html: this.state.related_article.introduction.toString().substring(0, 120) + '...'}}/>
                                    <div className="actions__wrap d-flex">
                                        <a href={'/article/' + this.state.related_article.slug} className="btn__custom btn__custom-green d-flex align-items-center justify-content-center">
                                            Читать
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    slideToUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    renderAuthorBox = () =>{
        if(this.state.meta.author !== undefined && this.state.meta.author !== '' && this.state.meta.author !== null){
            return(
                <div className="article__info-item">Автор: {this.state.meta.author}</div>
            )
        }
    }

    render() {
        return (
            <div className="page">
                <Helmet>
                    <title>Защита детей — {this.state.title}</title>
                </Helmet>
                <Header/>
                <div className="content">
                    <div className="section sc__article">
                        <div className="article__bg-box">
                            <div
                                className="article__bg"
                                style={{ backgroundImage: 'url(' + ((this.state.webp === true) ? this.state.poster.optimized : this.state.poster.general) + ')' }}
                            />
                        </div>
                        <div className="article__post-box">
                            <div className="decor decor-6" />
                            <div className="decor decor-7" />
                            <div className="decor decor-8" />
                            <div className="decor decor-9" />
                            <div className="container">
                                <div className="article__post">
                                    <div className="article__block">
                                        <div className="row row__custom justify-content-center">
                                            <div className="col-xl-8 col-lg-9 col-md-10 col-sm-12 col-12 col__custom">
                                                <div className="article__header">
                                                    <div className="article__title seo__item" style={{color: this.state.title_color}}>
                                                        <h1 dangerouslySetInnerHTML={{__html: this.state.title}}/>
                                                    </div>
                                                    <div className="article__info d-flex justify-content-between">
                                                        <div className="article__info-item">{this.state.meta.date_created}</div>
                                                        <div className="article__info-item">
                                                            Время чтения: {this.state.meta.reading_time}
                                                        </div>
                                                        {
                                                            this.renderAuthorBox()
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="article__block article__block-descr">
                                        <div className="row row__custom justify-content-center">
                                            <div className="col-xl-8 col-lg-9 col-md-10 col-sm-12 col-12 col__custom">
                                                <div className="article__description seo__item">
                                                    <h2 dangerouslySetInnerHTML={{__html: this.state.introduction}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="article__block">
                                        <div className="row row__custom justify-content-center">
                                            <div className="col-xl-8 col-lg-9 col-md-10 col-sm-12 col-12 col__custom">
                                                <div className="article__text-box">
                                                    <div className="article__text" dangerouslySetInnerHTML={{__html: this.state.html}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.renderRelativeArticle()
                    }
                    <div className={'section sc__product-front ' + ((this.state.mode === 'kid') ? 'sc__product-front-small' : '')}>
                        <div className="container">
                            <div className="sc__content">
                                <div className="row row__custom justify-content-end">
                                    <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 col__custom">
                                        <div className="sc__header">
                                            <div className="sc__title">
                                                Kaspersky
                                                <br />
                                                Safe Kids
                                            </div>
                                        </div>
                                        <div className="icon__list">
                                            <div className="icon__item d-flex">
                                                <div className="icon__item-img">
                                                    <img src={icon_time} />
                                                </div>
                                                <div className="icon__item-descr">
                                                    Контроль времени использования устройства
                                                </div>
                                            </div>
                                            <div className="icon__item d-flex">
                                                <div className="icon__item-img">
                                                    <img src={icon_target} />
                                                </div>
                                                <div className="icon__item-descr">
                                                    Данные о&nbsp;местонахождении ребёнка
                                                    <br />
                                                    и&nbsp;заряде батареи устройства
                                                </div>
                                            </div>
                                            <div className="icon__item d-flex">
                                                <div className="icon__item-img">
                                                    <img src={icon_gear} />
                                                </div>
                                                <div className="icon__item-descr">
                                                    Мониторинг активности
                                                    <br />
                                                    во&nbsp;ВКонтакте
                                                </div>
                                            </div>
                                        </div>
                                        <div className="actions__wrap d-flex">
                                            <Link to="/product" className="btn__custom d-flex align-items-center">
                                                Перейти на страницу продукта
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="static__img">
                                <img src={pr_fr_img} />
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={()=>{
                    this.slideToUp()
                }} className={'arrow__up d-flex align-items-center justify-content-center ' + ((this.state.scrollUp === true) ? 'active' : '')}>
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="14.6386" height="2.22498" transform="matrix(-0.701343 0.712824 0.701343 0.712824 11 1)" fill="#00A88E"/>
                        <rect width="14.6386" height="2.22498" transform="matrix(0.701342 0.712825 0.701342 -0.712825 9.45996 2.56543)" fill="#00A88E"/>
                    </svg>
                </div>
                <Footer/>
            </div>
        )
    }
}