import React from 'react'

import "../../assets/fonts/stylesheet.css"
import "../../assets/css/bootstrap.min.css"
import "../../assets/css/style.css"
import "../../assets/css/quiz.css"
import menMem from "../../assets/img/quiz/men-mem.jpg"

export default class QuizQuestionsList extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            selectedAnswer: -1
        }
    }

    handleAnswerClick(answerNumber, e) {
        const isCorrect =
            Boolean(this.props.questionsList[this.props.currentQuestion - 1].answers[answerNumber]["isCorrect"])

        this.setState({
            selectedAnswer: answerNumber
        })
        this.props.onAnswerBtnClick(isCorrect)
    }

    render() {
        const currentQuestion = this.props.questionsList[this.props.currentQuestion - 1];
        const answers = currentQuestion.answers;
        const mapImg = {
            'menMem': menMem
        };
        let answerClass = "";

        if (this.props.selectedAnswer) {
            answerClass = !this.props.answerType
                ? `quiz-question-answer-wrong-${this.state.selectedAnswer + 1}`
                : `quiz-question-answer-correct-${this.state.selectedAnswer + 1}`;
        }

        const paginationListItems = this.props.resultArray.map((item, index) => {
            const paginationClass = Boolean(item) ? item : '';
            return <li className={`quiz-question-pagination-item ${paginationClass}`} key={`pagination-item-${index}`} />
        });


        return (
            <div className="quiz-list quiz-bg">
                <div className="container d-flex align-items-center">
                    <div className={"quiz-img-" + currentQuestion.positionImg + " quiz-container"}>
                        <div className="quiz-container-header">
                            <div className="quiz-header">
                                <div className="quiz-header-title">
                                    Тест: {this.props.title}
                                </div>
                                <div className="quiz-header-number">
                                    {this.props.currentQuestion} из {this.props.questionsLength}
                                </div>
                            </div>
                        </div>
                        <div className="box-quiz-container">
                            {(Boolean(currentQuestion.img) && (currentQuestion.positionImg == "left")) &&
                                <div className="box-quiz-img-container">
                                <div className="quiz-question-img quiz-question-img-left">
                                        <img src={require('../../assets/img/quiz/' + currentQuestion.img + '.jpg')} alt="" />
                                    </div>
                                </div>
                            }
                            <div className="box-quiz-content">
                                {(Boolean(currentQuestion.img) && (currentQuestion.positionImg == "top") || (!Boolean(currentQuestion.positionImg) && Boolean(currentQuestion.img))) &&
                                    <div className="quiz-question-img quiz-question-img-top">
                                        <img src={require('../../assets/img/quiz/' + currentQuestion.img + '.jpg')} alt="" />
                                    </div>
                                }
                                <div className="quiz-question-text">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: currentQuestion.question }}
                                        className="quiz-question-text-inner"
                                    />
                                    {(Boolean(currentQuestion.img) && (currentQuestion.positionImg == "title")) &&
                                        <div className="quiz-question-img quiz-question-img-title">
                                        <img src={require('../../assets/img/quiz/' + currentQuestion.img + '.jpg')} alt=""/>
                                    </div>
                                    }
                                </div>
                                <ul className={`quiz-question-answers ${answerClass} ` + ((currentQuestion.type === 'net') ? 'net-mode' : '')}>
                                    <li
                                        className="quiz-question-answer"
                                        onClick={(e) => this.handleAnswerClick(0, e)}
                                    >
                                        <span className="quiz-question-answer-icon quiz-question-answer-icon-a">A</span>
                                        <div className="quiz-question-answer-text" dangerouslySetInnerHTML={{__html: answers[0].answer}}/>
                                        {Boolean(answers[0].img) &&
                                        <div className="quiz__answer-img">
                                            <img src={require('../../assets/img/quiz/' + answers[0].img + '.png')} alt=""/>
                                        </div>
                                        }
                                    </li>
                                    <li
                                        className="quiz-question-answer"
                                        onClick={(e) => this.handleAnswerClick(1, e)}
                                    >
                                        <span className="quiz-question-answer-icon quiz-question-answer-icon-b">B</span>
                                        <div className="quiz-question-answer-text" dangerouslySetInnerHTML={{__html: answers[1].answer}}/>
                                        {Boolean(answers[1].img) &&
                                        <div className="quiz__answer-img">
                                            <img src={require('../../assets/img/quiz/' + answers[1].img + '.png')} alt=""/>
                                        </div>
                                        }
                                    </li>
                                    {(Boolean(answers[2].answer) || (currentQuestion.type === 'net')) &&
                                        <li
                                            className="quiz-question-answer"
                                            onClick={(e) => this.handleAnswerClick(2, e)}
                                        >
                                            <span className="quiz-question-answer-icon quiz-question-answer-icon-c">C</span>
                                            <div className="quiz-question-answer-text" dangerouslySetInnerHTML={{ __html: answers[2].answer }} />
                                            {Boolean(answers[2].img) &&
                                                <div className="quiz__answer-img">
                                                    <img src={require('../../assets/img/quiz/' + answers[2].img + '.png')} alt="" />
                                                </div>
                                            }
                                        </li>
                                    }
                                    {(Boolean(answers[3].answer) || (currentQuestion.type === 'net'))  &&
                                    <li
                                        className="quiz-question-answer"
                                        onClick={(e) => this.handleAnswerClick(3, e)}
                                    >
                                        <span className="quiz-question-answer-icon quiz-question-answer-icon-d">D</span>
                                        <div className="quiz-question-answer-text" dangerouslySetInnerHTML={{__html: answers[3].answer}}/>
                                        {Boolean(answers[3].img) &&
                                        <div className="quiz__answer-img">
                                            <img src={require('../../assets/img/quiz/' + answers[3].img + '.png')} alt=""/>
                                        </div>
                                        }
                                    </li>
                                    }
                                </ul>                               
                                </div>
                        </div>
                        <ul className="quiz-question-pagination">
                            {paginationListItems}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
