import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import {Helmet} from "react-helmet"
import Slider from "react-slick"
import Footer from './Footer'
import Header from './Header'
import "../assets/fonts/stylesheet.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/slick.min.css"
import "../assets/css/style.css"
import { encode } from "base-64";
import {
    isSafari,
    isMacOs
} from "react-device-detect";
import primg1 from '../assets/img/promo-img-1.png';
import pr_fr_img from '../assets/img/pr-fr-img.png';
import icon_time from '../assets/img/icon-time.svg';
import icon_target from '../assets/img/icon-target.svg';
import icon_gear from '../assets/img/icon-gear.svg';



async function WebpIsSupported() {
    // If the browser doesn't has the method createImageBitmap, you can't display webp format
    if (!window.self.createImageBitmap) return false;

    // Base64 representation of a white point image
    const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

    // Retrieve the Image in Blob Format
    const blob = await fetch(webpData).then(r => r.blob());

    // If the createImageBitmap method succeeds, return true, otherwise false
    return createImageBitmap(blob).then(() => true, () => false);
}
export default class Text extends React.Component {
    constructor(props){
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            promoTiles: [],
            mode: 'parent',
            page_current: 1,
            page_count: 1,
            articles: [],
            webp: false,
            currentSlide: 1,
            topic: 'text',
            topic__title: 'Тексты',
            theme: 'light',
            scrollUp: false
        }

        this.updateMode = this.updateMode.bind(this);


    }

    updateMode = (mode) => {
        this.setState({
            mode: mode,
            page_current: 1,
            page_count: 1
        }, ()=>{
            localStorage.setItem('mode', mode);
            this.getFrontArticles(this.state.mode, this.state.page_current);
        })

    }

    slideToUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        /*let top_title = '';
        switch(this.props.match.params.direction){
            case 'text':
                top_title = 'Тексты';
                break;
            case 'video':
                top_title = 'Видео';
                break;
            case 'interactive':
                top_title = 'Интерактив';
                break;
            case 'soft':
                top_title = 'Cофт';
                break;
        }

        this.setState({
            topic__title: top_title
        });

        */

        if(localStorage.getItem('mode') !== undefined && localStorage.getItem('mode') !== null && localStorage.getItem('mode') !== ''){
            this.setState({
                mode: localStorage.getItem('mode')
            }, ()=>{
                this.getFrontArticles(this.state.mode, this.state.page_current);
            });
        }else{
            this.getFrontArticles(this.state.mode, this.state.page_current);
        }

        if(isSafari === true && isMacOs === true){
            this.setState({
                webp: false
            });
        }else{
            this.setState({
                webp: WebpIsSupported()
            });
        }


        document.body.classList.add('page-front');
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > window.outerHeight){
            this.setState({
                scrollUp: true
            });
        }else{
            this.setState({
                scrollUp: false
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        document.body.classList.remove('page-front');
    }

    getFrontArticles = async (mode, page) => {
        await fetch('/backend/api/getSectionIndex?type=' + mode + '&articles_grid_page=' + page + '&section=' + this.state.topic, {
            method: "GET"
        }).then((response) => response.json()).then((response) => {
            if(response.error === 0){
                if(response.articles_grid.page === 1){
                    this.setState({
                        articles: response.articles_grid.items
                    })
                }else{
                    response.articles_grid.items.map((newitem, i) => {
                        this.setState(prevState => ({
                            articles: [...prevState.articles, newitem]
                        }))
                    })

                }

                if(page === 1 && response.slider.items.length === 0){
                    this.setState({
                        theme: 'dark'
                    });
                }

                this.setState({
                    promoTiles: response.slider.items,
                    page_current: response.articles_grid.page,
                    page_count: response.articles_grid.pagesCount,
                    loading: false
                })
            }
        });
    }

    renderIcon = (icon) => {
        if(this.state.mode === 'kid'){
            if(icon === 'keyhole'){
                if(this.state.promoTiles.length > 0){
                    return(
                        <i className={'icon__decor ' + 'icon__' + icon}></i>
                    )
                }
            }else{
                return(
                    <i className={'icon__decor ' + 'icon__' + icon}></i>
                )
            }
        }
    }

    renderPromoSlides = () => {
        if(this.state.promoTiles.length > 0){
            return(
                this.state.promoTiles.map((slide, index) => (
                    <div key={index} className="promo__slider-item">
                        <div className="promo__slider-bg" />
                        <div className="promo__img">
                            <img src={((this.state.webp === true) ? slide.poster.optimized : slide.poster.general)} />
                        </div>
                        <div className="container d-flex align-items-end">
                            <div className="promo__content">
                                <div className="promo__title" dangerouslySetInnerHTML={{__html: slide.title}}/>
                                <div className="promo__descr" dangerouslySetInnerHTML={{__html: slide.preview.substring(0, 90) + '...'}}/>
                                <div className="actions__wrap d-flex">
                                    {slide.url ? 
                                        <a href={slide.url} className="btn__custom d-flex align-items-center justify-content-center">Подробнее</a> :
                                        <Link to={'/article/' + slide.slug} className="btn__custom d-flex align-items-center justify-content-center">Подробнее</Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )
        }
    }

    getArticleClasses = (numb) => {
        let class_list = 'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 col__custom article__item article__item-4';
        if(numb > 6){
            numb = numb % 6;
        }
        switch (numb){
            case 1:
                class_list = 'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 col__custom article__item article__item-4';
                break;
            case 2:
                class_list = 'col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 col__custom article__item article__item-1';
                break;
            case 3:
                class_list = 'col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 col__custom article__item article__item-3';
                break;
            case 4:
                class_list = 'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 col__custom article__item article__item-2';
                break;
            case 5:
                class_list = 'col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 col__custom article__item article__item-1';
                break;
            case 6:
                class_list = 'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 col__custom article__item article__item-4';
                break;
        }
        return class_list;
    }

    renderArticles = () => {
        return(
            this.state.articles.map((item, index) => (
                <div key={index} className={this.getArticleClasses(index + 1)}>
                    <Link to={'/article/' + item.slug}
                       className="article__item-block d-flex align-items-end"
                       style={{ color: "#fff" }}>
                        <div className="article__item-img">
                            <img src={((this.state.webp === true) ? item.poster.optimized : item.poster.resized)} />
                        </div>
                        <div className="article__item-content">
                            <div className="article__item-category" style={{color: item.title_color}}>{item.section_title}</div>
                            <div className="article__item-title" style={{color: item.title_color}} dangerouslySetInnerHTML={{__html: item.title}}/>
                        </div>
                        {((index + 1) % 6 === 1) ? this.renderIcon('light') : ''}
                        {((index + 1) % 6 === 2) ? this.renderIcon('eye2') : ''}
                        {((index + 1) % 6 === 3) ? this.renderIcon('eye') : ''}
                        {((index + 1) % 6 === 4) ? this.renderIcon('planet') : ''}
                        {((index + 1) % 6 === 5) ? this.renderIcon('cursors') : ''}
                        {((index + 1) % 6 === 0) ? this.renderIcon('emoji') : ''}
                    </Link>
                </div>
            ))
        )
    }

    renderArticlesLoader = () => {
        if(this.state.page_current !== this.state.page_count){
            return(
                <div className={'actions__wrap d-flex justify-content-center ' + ((this.state.loading) ? 'disabled' : '')}>
                    <div id="load-more" onClick={()=>{
                        this.setState({
                            loading: true
                        });
                        //this.myRef.scrollIntoView({ behavior: 'smooth', block: 'start' })
                        this.loadArticles();
                    }}   ref={ (ref) => this.myRef=ref } className="btn__border d-flex align-items-center justify-content-center">
                        <span>Показать ещё</span>
                        <i className="icon__arrow">
                            <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.3999 5.69561L12.6375 4L8.02186 8.57527L3.33628 4.06731L1.59998 5.78841L6.54569 10.5467L7.99995 12L8.20234 11.8006L9.77648 10.2634L9.76961 10.2568L14.3999 5.69561Z"
                                    fill="#00A88E"
                                />
                            </svg>
                        </i>
                    </div>
                </div>
            )
        }
    }

    loadArticles = () => {
        this.getFrontArticles(this.state.mode, this.state.page_current + 1);
        let loadmore = document.getElementById('load-more');
        let offset = loadmore.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ behavior: 'smooth', top: offset});
    }

    renderPromoNav = () => {
        if(this.state.promoTiles.length > 1){
            return(
                <div className="slide__navs d-flex align-items-center">
                    <div onClick={this.previous} className="slide__nav slide__prev">
                        <svg
                            width={64}
                            height={64}
                            viewBox="0 0 64 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M39.7446 11.7858L51.6627 24.304C52.6672 25.3574 53.098 26.834 52.8181 28.2625L50.0983 42.1733C49.9117 43.1302 49.4187 43.9985 48.694 44.6505L39.1054 53.2708C37.6079 54.6168 35.395 54.8004 33.6969 53.7174L13.0766 40.5737C11.607 39.637 10.8124 37.9347 11.0379 36.2059L13.0004 21.2028C13.2181 19.5394 14.344 18.1359 15.9208 17.5648L34.9689 10.6623C36.6422 10.0555 38.5161 10.4958 39.7446 11.7858ZM38.2963 13.1651C37.6582 12.495 36.7061 12.2386 35.8254 12.4864L35.6503 12.5427L16.6018 19.4453C15.7867 19.7405 15.1886 20.4377 15.0143 21.2796L14.9835 21.4622L13.0211 36.4645C12.9038 37.3637 13.2837 38.2496 14.0032 38.7849L14.1516 38.8872L34.7723 52.0311C35.657 52.5954 36.7953 52.5387 37.6179 51.9084L37.7683 51.7835L47.3563 43.1637C47.7088 42.8466 47.9617 42.4386 48.0888 41.986L48.1355 41.7895L50.8554 27.8779C50.9986 27.1471 50.8061 26.3934 50.3371 25.822L50.2142 25.683L38.2963 13.1651Z"
                                fill="white"
                            />
                            <rect
                                width="9.86878"
                                height="1.48032"
                                transform="matrix(0.7049 -0.709307 0.7049 0.709307 28 32)"
                                fill="white"
                            />
                            <rect
                                width="9.86878"
                                height="1.48032"
                                transform="matrix(0.7049 0.709307 -0.7049 0.709307 29.0435 30.95)"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div onClick={this.next} className="slide__nav slide__next">
                        <svg
                            width={64}
                            height={64}
                            viewBox="0 0 64 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M46.3895 58.7581L60.0932 33.5779C61.0545 31.8119 61.1121 29.6918 60.2488 27.8768L51.0789 8.58313C50.0024 6.32019 47.6833 4.91251 45.1809 5.00423L18.4619 5.98533C16.243 6.06638 14.2308 7.31622 13.1735 9.2699L3.75849 26.6653C2.99113 28.0815 2.79716 29.7366 3.21707 31.2915L9.09375 53.0997C9.73322 55.4736 11.6985 57.2566 14.1221 57.6597L39.8456 61.9489C42.4845 62.3882 45.1105 61.1085 46.3895 58.7581Z"
                                stroke="white"
                                strokeWidth={2}
                            />
                            <rect
                                width="9.86878"
                                height="1.48032"
                                transform="matrix(-0.7049 -0.709307 -0.7049 0.709307 36 32)"
                                fill="white"
                            />
                            <rect
                                width="9.86878"
                                height="1.48032"
                                transform="matrix(-0.7049 0.709307 0.7049 0.709307 34.9565 30.95)"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div className="slide__info d-flex align-items-center">
                        <div className="slide__info-current">
                            <span>{this.state.currentSlide}</span>
                        </div>
                        <div className="slide__info-count">
                            /<span>{this.state.promoTiles.length}</span>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const sl_set = {
            infinite: true,
            dots: false,
            swipeToSlide: true,
            adaptiveHeight: true,
            speed: 1000,
            beforeChange: (oldIndex, newIndex)=>{
                let val = (newIndex + 1);
                if(val === 0){
                    val = 1;
                }
                this.setState({
                    currentSlide: val
                })
            }
        };
        return (
            <div className="page">
                <Helmet>
                    <title>Защита детей</title>
                </Helmet>
                <Header theme={this.state.theme} page={'home'} topic={'text'} updateMode={this.updateMode}/>
                <div className="content">
                    <div className={'section sc__promo ' + ((this.state.promoTiles.length === 0) ? 'hidden' : '')}>
                        <div className="promo__slider-box">
                            <Slider {...sl_set} ref={c => (this.slider = c)}>
                                {
                                    this.renderPromoSlides()
                                }
                            </Slider>
                            {
                                this.renderPromoNav()
                            }
                            {/*{
                                this.renderIcon('keyhole')
                            }*/}
                        </div>
                    </div>
                    <div className={'section sc__common sc__articles ' + ((this.state.promoTiles.length === 0) ? 'first-section' : '')}>
                        <div className="decor decor-3" />
                        <div className="decor decor-4" />
                        <div className="decor decor-5" />

                        <div className="container">
                            <div className="articles__list-title">
                                {this.state.topic__title}
                            </div>
                            <div className="articles__list masonry-list">
                                <div className="row row__custom">
                                    {
                                        this.renderArticles()
                                    }
                                </div>
                            </div>
                            {
                                this.renderArticlesLoader()
                            }
                        </div>
                    </div>
                    <div className={'section sc__product-front ' + ((this.state.mode === 'kid') ? 'sc__product-front-small' : '')}>
                        <div className="container">
                            <div className="sc__content">
                                <div className="row row__custom justify-content-end">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 col__custom">
                                        <div className="sc__header">
                                            <div className="sc__title">
                                                Kaspersky
                                                <br />
                                                Safe Kids
                                            </div>
                                        </div>
                                        <div className="icon__list">
                                            <div className="icon__item d-flex">
                                                <div className="icon__item-img">
                                                    <img src={icon_time} />
                                                </div>
                                                <div className="icon__item-descr">
                                                    Контроль времени использования устройства
                                                </div>
                                            </div>
                                            <div className="icon__item d-flex">
                                                <div className="icon__item-img">
                                                    <img src={icon_target} />
                                                </div>
                                                <div className="icon__item-descr">
                                                    Данные о&nbsp;местонахождении ребёнка
                                                    <br />
                                                    и&nbsp;заряде батареи устройства
                                                </div>
                                            </div>
                                            <div className="icon__item d-flex">
                                                <div className="icon__item-img">
                                                    <img src={icon_gear} />
                                                </div>
                                                <div className="icon__item-descr">
                                                    Мониторинг активности
                                                    <br />
                                                    во&nbsp;ВКонтакте
                                                </div>
                                            </div>
                                        </div>
                                        <div className="actions__wrap d-flex">
                                            <Link to="/product" className="btn__custom d-flex align-items-center">
                                                Перейти на страницу продукта
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="static__img">
                                <img src={pr_fr_img} />
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={()=>{
                    this.slideToUp()
                }} className={'arrow__up d-flex align-items-center justify-content-center ' + ((this.state.scrollUp === true) ? 'active' : '')}>
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="14.6386" height="2.22498" transform="matrix(-0.701343 0.712824 0.701343 0.712824 11 1)" fill="#00A88E"/>
                        <rect width="14.6386" height="2.22498" transform="matrix(0.701342 0.712825 0.701342 -0.712825 9.45996 2.56543)" fill="#00A88E"/>
                    </svg>
                </div>
                <Footer/>
            </div>
        )

    }
}