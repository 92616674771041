import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const List = styled("ul")({
  display: "block",
  // borderBottom: "1.5px solid #ededed",
  whiteSpace: "nowrap",
  overflowX: "auto",
  overflowY: "hidden",
  position: "relative",
  zIndex: 0,
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "&:after": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: 1,
    bottom: 0,
    left: 0,
    backgroundColor: "#ededed",
    zIndex: -1,
  },
});
const Item = styled("li")({
  position: "relative",
  fontSize: 14,
  lineHeight: 1.3,
  justifyContent: "center",
  display: "inline-block",
});

const Link = styled(NavLink)({
  color: "#1d1d1b",
  display: "block",
  padding: "22px 16px",
  borderBottom: "4px solid transparent",
  "&:hover": {
    borderBottomColor: "#ccc",
    color: "inherit",
  },
  "&.active": {
    borderBottomColor: "#23d1ae",
  },
});

export const NavTabs = () => {
  return (
    <List>
      {[
        { name: "junior", label: "Для младших классов" },
        { name: "middle", label: "Для средних и старших классов" },
        {
          name: "information-security-7th-grade",
          label: "Информационная безопасность. 7 класс",
        },
      ].map(({ name, label }) => (
        <Item>
          <Link to={`/metodic/${name}`}>{label}</Link>
        </Item>
      ))}
    </List>
  );
};
