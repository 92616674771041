import React from 'react'
import { Link } from 'react-router-dom'
import 'url-search-params-polyfill';
import Footer from './Footer'
import Header from './Header'
import {Helmet} from "react-helmet"

export default class Search extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            keyword: '',
            results: [],
            page_current: 1,
            page_count: 1,
            mode: 'parent'
        }
        this.updateMode = this.updateMode.bind(this);
    }

    updateMode = (mode) => {
        this.setState({
            mode: mode,
            page_current: 1,
            page_count: 1
        }, ()=>{
            localStorage.setItem('mode', mode);
            this.searchByKeyword(this.state.page_current);
            let urlParams = new URLSearchParams(this.props.location.search);
            urlParams.set('type', mode);
            window.history.pushState({}, document.title, window.location.href.split('?')[0] + '?' + urlParams);
        })

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if(localStorage.getItem('mode') !== undefined && localStorage.getItem('mode') !== null && localStorage.getItem('mode') !== ''){
            this.setState({
                mode: localStorage.getItem('mode')
            });
        }
        /*if(localStorage.getItem('keyword') !== undefined && localStorage.getItem('keyword') !== false && localStorage.getItem('keyword') !== null){
            this.setState({
                keyword: localStorage.getItem('keyword')
            }, ()=>{
                localStorage.removeItem('keyword');
                this.searchByKeyword(this.state.page_current)
            });
        }*/

        let urlParams = new URLSearchParams(this.props.location.search);
        let keyword = urlParams.get('keyword');
        let mode = urlParams.get('type');
        if(keyword !== undefined && keyword !== null && keyword !== ''){
            this.setState({
                keyword: keyword
            }, ()=>{
                this.searchByKeyword(this.state.page_current)
            })
        }
        if(mode !== undefined && mode !== null && mode !== ''){
            this.setState({
                mode: mode
            })
        }
    }

    renderResults = () => {
        if(this.state.results.length > 0){
            return(
                this.state.results.map((result, i)=>(
                    <div key={i} className="results__item">
                        <div className="results__item-title">
                            <Link to={'/' + result.title.type + '/' + result.title.slug}>{result.title.text}</Link>
                        </div>
                        <div className="results__item-descr">
                            {result.short_text}
                        </div>
                    </div>
                ))
            )
        }else{
            if(this.state.keyword !== ''){
                return(
                    <div className="empty__message text-center">По вашему запросу ничего не найдено</div>
                )
            }
        }
    }

    searchByKeyword = async(page) => {
        if(this.state.keyword.length > 2){
            await fetch('/backend/api/searchContents?keyword=' + this.state.keyword + '&page=' + page + '&type=' + this.state.mode, {
                method: "GET"
            }).then((response) => response.json()).then((response) => {
                if(response.error === 0){
                    this.setState({
                        results: response.result,
                        page_current: response.paging.current_page,
                        page_count: response.paging.total_pages
                    })
                }
            });
        }
    }

    renderPager = () => {
        let paging_arr = this.pagination(this.state.page_current, this.state.page_count);
        if(this.state.page_count > 1){
            return(
                <div className="pager__wrap">
                    <nav>
                        <ul className="pagination">
                            {
                                paging_arr.map((page, index) => (
                                    <li key={index} className="page-item">
                                        <a onClick={()=>{this.searchByKeyword(parseInt(page))}} className={'page-link ' + ((page === this.state.page_current) ? 'active' : '')}  tabIndex={-1}>
                                            {page}
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                    </nav>
                </div>
            )
        }
    }

    pagination = (c, m) => {
        let current = c,
            last = m,
            delta = 2,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    render() {
        return (
            <div className="page">
                <Helmet>
                    <title>Защита детей — Поиск</title>
                </Helmet>
                <Header mode={this.state.mode} page={'search'}  updateMode={this.updateMode}/>
                <div className="content">
                    <div className="section sc__search">
                        <div className="decor decor-15" />
                        <div className="container">
                            <div className="search__box d-flex align-items-center">
                                <div onClick={()=>{
                                    this.searchByKeyword(1);
                                }} className="search__submit">
                                    <svg
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M9.25 4.9998C11.594 4.9998 13.5 6.9068 13.5 9.2498C13.5 11.5928 11.594 13.4998 9.25 13.4998C6.906 13.4998 5 11.5928 5 9.2498C5 6.9068 6.906 4.9998 9.25 4.9998ZM9.25 15.4998C10.431 15.4998 11.531 15.1648 12.475 14.5938H12.474L16.732 18.8538C16.928 19.0488 17.245 19.0488 17.439 18.8538L18.854 17.4398C19.049 17.2448 19.049 16.9278 18.854 16.7318L14.595 12.4728V12.4738C15.165 11.5308 15.5 10.4298 15.5 9.2498C15.5 5.8038 12.696 2.9998 9.25 2.9998C5.804 2.9998 3 5.8038 3 9.2498C3 12.6958 5.804 15.4998 9.25 15.4998Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <div className="search__field">
                                    <input onChange={(keyword)=>{
                                        this.setState({keyword: keyword.target.value}, ()=>{
                                            this.searchByKeyword(1);
                                        });
                                    }} type="text"
                                        placeholder="Поиск..."
                                        defaultValue={this.state.keyword}
                                    />
                                </div>
                                <div onClick={()=>{
                                    this.setState({
                                        keyword: ''
                                    });
                                }} className="search__close">
                                    <svg
                                        width={64}
                                        height={64}
                                        viewBox="0 0 64 64"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M32.1627 33.9685L38.5613 40.3671L40.6826 38.2458L34.284 31.8471L41.0096 25.1215L38.8883 23.0002L32.1627 29.7258L25.4368 23L23.3155 25.1213L30.0413 31.8471L23.6425 38.246L25.7638 40.3673L32.1627 33.9685Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section sc__results">
                        <div className="container">
                            <div className="results__wrap">
                                <div className="row row__custom justify-content-center">
                                    <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12 col__custom">
                                        <div className="results__list">
                                            {
                                                this.renderResults()
                                            }
                                        </div>
                                        {
                                            this.renderPager()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}