import React from "react";
import doc from "../../assets/img/doc-1.svg";

export const DocItem = ({ href, children }) => (
  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 col__custom docs__item">
    <div className="docs__item-block d-flex align-items-start">
      <div className="docs__item-img">
        <img src={doc} />
      </div>
      <div className="docs__item-content">
        <div className="docs__item-title">{children}</div>
        <div className="actions__wrap d-flex">
          <a
            target="_blank"
            href={href}
            className="link__download d-flex align-items-center"
          >
            <span>Скачать</span>
            <i className="icon__download">
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.76942 7.27528L8.31905 5.82491L9.34398 4.79999L12.544 7.99999L9.34398 11.2L8.31905 10.1751L9.76938 8.72474H4V7.27528H9.76942Z"
                  fill="#00A88E"
                />
              </svg>
            </i>
          </a>
        </div>
      </div>
    </div>
  </div>
);
