/* global gtag */
import React from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import "url-search-params-polyfill";
import logo from "../assets/img/logo.svg";
import logo_green from "../assets/img/logo-green.svg";
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "parent",
      keyword: "",
      search: false,
      redirect: false,
      searchError: false,
      mobile_nav: false,
      topic: "",
    };
  }

  changeMode = (mode) => {
    this.setState({
      mode: mode,
    });
    try {
      gtag("config", "UA-63997723-86", { mode: mode });
    } catch (_) {}

    if (this.props.page === "home") {
      this.props.updateMode(mode);
    }
    if (this.props.page === "search") {
      this.props.updateMode(mode);
    }
    localStorage.setItem("mode", mode);
  };

  componentDidMount() {
    if (
      localStorage.getItem("mode") !== undefined &&
      localStorage.getItem("mode") !== null &&
      localStorage.getItem("mode") !== ""
    ) {
      this.setState({
        mode: localStorage.getItem("mode"),
      });
    }
    /*if(this.props.mode === 'kid'){
            this.setState({
                mode: 'kid'
            });
        }


        if(this.props.mode === 'parent'){
            this.setState({
                mode: 'parent'
            });
        }*/
  }

  searchSubmit = () => {
    if (this.state.keyword.length > 2) {
      document.body.classList.remove("search-mode");
      this.setState({
        redirect: true,
      });
    } else {
      this.setState({
        searchError: true,
      });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.searchSubmit();
    }
  };

  renderSoftLink = () => {
    if (this.state.mode !== "kid") {
      return (
        <li className={this.props.topic === "soft" ? "active" : ""}>
          <Link to="/product">Защита в сети</Link>
        </li>
      );
    }
  };

  renderLogo = () => {
    if (this.props.theme === "dark") {
      return <img src={logo_green} />;
    } else {
      return <img src={logo} />;
    }
  };

  render() {
    if (this.state.redirect === true) {
      return (
        <Redirect
          to={
            "/search?keyword=" + this.state.keyword + "&type=" + this.state.mode
          }
        />
      );
    } else {
      return (
        <div
          className={
            "header " + (this.props.theme === "dark" ? "dark-theme" : "")
          }
        >
          <div className="container d-flex align-items-center justify-content-between">
            <div className="logo__box d-flex">
              <Link
                onClick={() => {
                  document.body.classList.remove("search-mode");
                }}
                to="/"
                className="logo"
              >
                {this.renderLogo()}
              </Link>
            </div>
            <div className="header__right d-flex align-items-center">
              <div className="main__nav">
                <ul>
                  <li className={this.props.topic === "video" ? "active" : ""}>
                    <Link
                      to={{
                        pathname: "/video",
                      }}
                    >
                      Видео
                    </Link>
                  </li>
                  <li className={this.props.topic === "text" ? "active" : ""}>
                    <NavLink strict to="/text">
                      Тексты
                    </NavLink>
                  </li>
                  <li
                    className={
                      this.props.topic === "interactive" ? "active" : ""
                    }
                  >
                    <a href="/category/interactive">Интерактивы</a>
                  </li>
                  <li>
                    <a href="/serial/">Сериал</a>
                  </li>
                  {this.renderSoftLink()}
                  <li>
                    <a href="/article/cifrovye_privychki">Цифровые привычки</a>
                  </li>
                  <li
                    className={this.props.topic === "metodic" ? "active" : ""}
                  >
                    <a href="/metodic">Учителям</a>
                  </li>
                </ul>
              </div>
              <div
                onClick={() => {
                  if (document.body.classList.contains("search-mode")) {
                    document.body.classList.remove("search-mode");
                  } else {
                    document.body.classList.add("search-mode");
                  }
                }}
                className="search__toggle"
              >
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.25 4.9998C11.594 4.9998 13.5 6.9068 13.5 9.2498C13.5 11.5928 11.594 13.4998 9.25 13.4998C6.906 13.4998 5 11.5928 5 9.2498C5 6.9068 6.906 4.9998 9.25 4.9998ZM9.25 15.4998C10.431 15.4998 11.531 15.1648 12.475 14.5938H12.474L16.732 18.8538C16.928 19.0488 17.245 19.0488 17.439 18.8538L18.854 17.4398C19.049 17.2448 19.049 16.9278 18.854 16.7318L14.595 12.4728V12.4738C15.165 11.5308 15.5 10.4298 15.5 9.2498C15.5 5.8038 12.696 2.9998 9.25 2.9998C5.804 2.9998 3 5.8038 3 9.2498C3 12.6958 5.804 15.4998 9.25 15.4998Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="mode__nav">
                <ul>
                  <li className={this.state.mode === "parent" ? "active" : ""}>
                    <a
                      onClick={() => {
                        this.changeMode("parent");
                      }}
                    >
                      Родитель
                    </a>
                  </li>
                  <li className={this.state.mode === "kid" ? "active" : ""}>
                    <a
                      onClick={() => {
                        this.changeMode("kid");
                      }}
                    >
                      Ребёнок
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={
                  "search__box d-flex align-items-center " +
                  (this.state.search === true ? "active" : "")
                }
              >
                <div
                  onClick={() => {
                    this.searchSubmit();
                  }}
                  className="search__submit"
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.25 4.9998C11.594 4.9998 13.5 6.9068 13.5 9.2498C13.5 11.5928 11.594 13.4998 9.25 13.4998C6.906 13.4998 5 11.5928 5 9.2498C5 6.9068 6.906 4.9998 9.25 4.9998ZM9.25 15.4998C10.431 15.4998 11.531 15.1648 12.475 14.5938H12.474L16.732 18.8538C16.928 19.0488 17.245 19.0488 17.439 18.8538L18.854 17.4398C19.049 17.2448 19.049 16.9278 18.854 16.7318L14.595 12.4728V12.4738C15.165 11.5308 15.5 10.4298 15.5 9.2498C15.5 5.8038 12.696 2.9998 9.25 2.9998C5.804 2.9998 3 5.8038 3 9.2498C3 12.6958 5.804 15.4998 9.25 15.4998Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div
                  className={
                    "search__field " +
                    (this.state.searchError === true ? "error-field" : "")
                  }
                >
                  <input
                    onKeyPress={this.handleKeyPress}
                    onChange={(keyword) => {
                      this.setState({
                        keyword: keyword.target.value,
                        searchError: false,
                      });
                      localStorage.setItem("keyword", keyword.target.value);
                    }}
                    type="text"
                    placeholder="Поиск..."
                    value={this.state.keyword}
                  />
                </div>
                <div
                  onClick={() => {
                    document.body.classList.remove("search-mode");
                  }}
                  className="search__close"
                >
                  <svg
                    width={64}
                    height={64}
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M32.1627 33.9685L38.5613 40.3671L40.6826 38.2458L34.284 31.8471L41.0096 25.1215L38.8883 23.0002L32.1627 29.7258L25.4368 23L23.3155 25.1213L30.0413 31.8471L23.6425 38.246L25.7638 40.3673L32.1627 33.9685Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div
                onClick={() => {
                  this.setState({
                    mobile_nav: true,
                  });
                }}
                className="nav__bars"
              >
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 4.66667H0V1.66667H20V4.66667Z"
                    fill="url(#paint0_linear)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 11.3333H0V8.33333H20V11.3333Z"
                    fill="url(#paint1_linear)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 18H0V15H20V18Z"
                    fill="url(#paint2_linear)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1={10}
                      y1="1.66667"
                      x2={10}
                      y2="4.66667"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset={1} stopColor="white" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1={10}
                      y1="8.33333"
                      x2={10}
                      y2="11.3333"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset={1} stopColor="white" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1={10}
                      y1={15}
                      x2={10}
                      y2={18}
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset={1} stopColor="white" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div
            className={
              "mobile__nav-box " +
              (this.state.mobile_nav === true ? "active" : "")
            }
          >
            <div className="container">
              <div className="mobile__nav-header d-flex align-items-center justify-content-between">
                <div className="logo__box d-flex">
                  <Link
                    onClick={() => {
                      document.body.classList.remove("search-mode");
                    }}
                    to="/"
                    className="logo"
                  >
                    <img src={logo_green} />
                  </Link>
                </div>
                <div
                  onClick={() => {
                    this.setState({
                      mobile_nav: false,
                    });
                  }}
                  className="close__nav"
                ></div>
              </div>
              <div className="mode__nav">
                <ul>
                  <li className={this.state.mode === "parent" ? "active" : ""}>
                    <a
                      onClick={() => {
                        this.changeMode("parent");
                      }}
                    >
                      Родитель
                    </a>
                  </li>
                  <li className={this.state.mode === "kid" ? "active" : ""}>
                    <a
                      onClick={() => {
                        this.changeMode("kid");
                      }}
                    >
                      Ребёнок
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mobile__nav">
                <ul>
                  <li className={this.props.topic === "video" ? "active" : ""}>
                    <Link to="/video">Видео</Link>
                  </li>
                  <li className={this.props.topic === "text" ? "active" : ""}>
                    <Link to="/text">Тексты</Link>
                  </li>
                  <li
                    className={
                      this.props.topic === "interactive" ? "active" : ""
                    }
                  >
                    <a href="/category/interactive">Интерактивы</a>
                  </li>
                  {this.renderSoftLink()}
                </ul>
              </div>
              <div className="search__box d-flex align-items-center">
                <div
                  onClick={() => {
                    this.searchSubmit();
                  }}
                  className="search__submit"
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.25 4.9998C11.594 4.9998 13.5 6.9068 13.5 9.2498C13.5 11.5928 11.594 13.4998 9.25 13.4998C6.906 13.4998 5 11.5928 5 9.2498C5 6.9068 6.906 4.9998 9.25 4.9998ZM9.25 15.4998C10.431 15.4998 11.531 15.1648 12.475 14.5938H12.474L16.732 18.8538C16.928 19.0488 17.245 19.0488 17.439 18.8538L18.854 17.4398C19.049 17.2448 19.049 16.9278 18.854 16.7318L14.595 12.4728V12.4738C15.165 11.5308 15.5 10.4298 15.5 9.2498C15.5 5.8038 12.696 2.9998 9.25 2.9998C5.804 2.9998 3 5.8038 3 9.2498C3 12.6958 5.804 15.4998 9.25 15.4998Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div
                  className={
                    "search__field " +
                    (this.state.searchError === true ? "error-field" : "")
                  }
                >
                  <input
                    onKeyPress={this.handleKeyPress}
                    onChange={(keyword) => {
                      this.setState({
                        keyword: keyword.target.value,
                        searchError: false,
                      });
                      localStorage.setItem("keyword", keyword.target.value);
                    }}
                    type="text"
                    placeholder="Поиск..."
                    value={this.state.keyword}
                  />
                </div>
                <div
                  onClick={() => {
                    document.body.classList.remove("search-mode");
                  }}
                  className="search__close"
                >
                  <svg
                    width={64}
                    height={64}
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M32.1627 33.9685L38.5613 40.3671L40.6826 38.2458L34.284 31.8471L41.0096 25.1215L38.8883 23.0002L32.1627 29.7258L25.4368 23L23.3155 25.1213L30.0413 31.8471L23.6425 38.246L25.7638 40.3673L32.1627 33.9685Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
