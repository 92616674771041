import React from 'react'

import "../../assets/fonts/stylesheet.css"
import "../../assets/css/bootstrap.min.css"
import "../../assets/css/style.css"
import "../../assets/css/quiz.css"

import correctIcon from "../../assets/img/quiz/right-icon.svg"
import wrongIcon from "../../assets/img/quiz/wrong-icon.svg"

export default class QuizModal extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        const currentQuestion = this.props.questionsList[this.props.currentQuestion - 1];
        const answerType = this.props.answerType;
        const modalTitle = answerType ? currentQuestion.correctTitle : currentQuestion.wrongTitle;
        const modalIcon = answerType ? correctIcon : wrongIcon;
        const modalText = answerType ? currentQuestion.correctResult : currentQuestion.wrongResult;
        const btnText = this.props.isLastQuestion ? "Показать результаты" : "Следующий вопрос";

        return (
            <div className={`quiz-modal ${this.props.isShowModal ? "quiz-modal-visible" : ""}`}>
                <div className="quiz-modal-inner">
                    <div className="quiz-modal-img">
                        <img src={modalIcon} alt=""/>
                    </div>
                    <div className="quiz-modal-content">
                        <div className="quiz-modal-title">
                            {modalTitle}
                        </div>
                        <div className="quiz-modal-text" dangerouslySetInnerHTML={{ __html: modalText }} />
                        <button
                            className={`btn__custom quiz-button quiz-button-arrow btn__custom-green`}
                            onClick={this.props.nextQuestion}
                        >
                            {btnText}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
