import React from 'react'

import "../../assets/fonts/stylesheet.css"
import "../../assets/css/bootstrap.min.css"
import "../../assets/css/style.css"
import "../../assets/css/quiz.css"
import girlImg from "../../assets/img/quiz/girl-img.svg"
import heyImg from "../../assets/img/quiz/hey-chat.svg"
import test7Img from "../../assets/img/quiz/chto-eto-znachit.svg"
import test8Img from "../../assets/img/quiz/chto-vy-znayete-o-memakh.svg"
import test4 from "../../assets/img/quiz/test4.svg"
import test5 from "../../assets/img/quiz/test5.svg"
import test6 from "../../assets/img/quiz/test6.svg"
export default class QuizPreview extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        let url_parts = window.location.href.split('/');

        if(url_parts[url_parts.length - 1] !== undefined){
            this.setState({
                url: url_parts[url_parts.length - 1].toLowerCase()
            })
        }
    }

    componentWillUnmount() {

    }

    renderQuiz = () => {
        switch(this.state.url){
            case 'test-nub-guru-data':
                return(
                    <div className="quiz-preview quiz-bg">
                        <div className="container d-flex flex-row justify-content-between">
                            <div className="quiz-preview-description">
                                <h1 className="quiz-preview-title">
                                    Нуб или гуру:
                                </h1>
                                <p className="quiz-preview-text">
                                    Как хорошо вы ориентируетесь в реалиях современного Интернета? Приходится ли вам пользоваться словариком, чтобы понять, о чём шутит «Лентач», или вы и сами готовы научить собеседника паре новых выражений и в курсе всех последних мемасов?
                                    <br/><br/>
                                    Пройдите наш тест и узнайте!
                                </p>
                                <button
                                    className="btn__custom quiz-button quiz-button-arrow"
                                    onClick={()=>{
                                        this.props.onStartBtnClick('test-nub-guru-data', true, 'Нуб или гуру?', '', 2)
                                    }}>
                                    Начать тест
                                </button>
                            </div>
                            <div className="quiz-preview-img">
                                <img src={girlImg} alt=""/>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 'test-chto-vy-znaete-ob-istorii-interneta':
                return(
                    <div>
                        <div className="quiz-preview quiz-bg">
                            <div className="container d-flex flex-row justify-content-between">
                                <div className="quiz-preview-description">
                                    <div className="quiz-preview-wrap">
                                        <h1 className="quiz-preview-title">
                                            Что вы знаете об
                                            истории интернета?
                                        </h1>
                                        <p className="quiz-preview-text">
                                            Если бы интернет был человеком, он бы уже давно закончил школу
                                            и университет, сменил пару-тройку мест работы, возглавил
                                            какой-нибудь отдел, возможно, женился бы и завел ребенка.
                                            <br/><br/>
                                            Проверим, как вы помните основные вехи его жизни?
                                        </p>
                                        <button
                                            className="btn__custom quiz-button quiz-button-arrow"
                                            onClick={()=>{
                                                this.props.onStartBtnClick('test-chto-vy-znaete-ob-istorii-interneta', true, 'Что вы знаете об истории интернета?', '', 3)
                                            }}>
                                            Начать тест
                                        </button>
                                    </div>
                                </div>
                                <div className="quiz-preview-img">
                                    <img src={girlImg} alt=""/>
                                </div>
                            </div>
                        </div>

                    </div>
                )
                break;
            case 'test-hey-chat-kak-dela-data':
                return(
                    <div className="quiz-preview quiz-bg quiz__chat">
                        <div className="container d-flex flex-row justify-content-between">
                            <div className="quiz-preview-description">
                                <h1 className="quiz-preview-title">
                                    «Эй,&nbsp;чат,<br/>
                                    как дела?»
                                </h1>
                                <p className="quiz-preview-text">
                                    Смотришь Twitch? А&nbsp;знаешь, как называются<br/>
                                    смайлы? Проверь себя!
                                </p>
                                <button
                                    className="btn__custom quiz-button quiz-button-arrow"
                                    onClick={()=>{
                                        this.props.onStartBtnClick('test-hey-chat-kak-dela-data', false, '«Эй, чат, как дела?»', 'simple', 1)
                                    }}>
                                    Начать тест
                                </button>
                            </div>
                            <div className="quiz-preview-img">
                                <img src={heyImg} alt=""/>
                            </div>
                        </div>
                    </div>
                )
                break;

            case 'test-prilichnom-obshhestve-znaete-li-vy-setevoj-jetiket':
                return(
                    <div className="quiz-preview quiz-bg quiz__chat quiz__test4">
                        <div className="container d-flex flex-row justify-content-between">
                            <div className="quiz-preview-description">
                                <h1 className="quiz-preview-title">
                                    В&nbsp;приличном обществе: знаете&nbsp;ли вы&nbsp;сетевой этикет?
                                </h1>
                                <p className="quiz-preview-text">
                                    Вы&nbsp;наверняка переживаете, как там ваши дети общаются в&nbsp;соцсетях. Вдруг они болтают лишнее, наживают врагов и&nbsp;выставляют себя в&nbsp;плохом свете? Понимаем.<br/><br/>
                                    Но&nbsp;как насчет вас самих? Давайте проверим, что у&nbsp;вас с&nbsp;сетевым этикетом.
                                </p>
                                <button
                                    className="btn__custom quiz-button quiz-button-arrow"
                                    onClick={()=>{
                                        this.props.onStartBtnClick('test-prilichnom-obshhestve-znaete-li-vy-setevoj-jetiket', true, '«В приличном обществе: знаете ли вы сетевой этикет?»', '', 4)
                                    }}>
                                    Начать тест
                                </button>
                            </div>
                            <div className="quiz-preview-img">
                                <img src={test4} alt=""/>
                            </div>
                        </div>
                    </div>
                )
                break;

            case 'test-vu-vse-pravilno-obyasnili':
                return(
                    <div className="quiz-preview quiz-bg quiz__chat quiz__test6">
                        <div className="container d-flex flex-row justify-content-between">
                            <div className="quiz-preview-description">
                                <h1 className="quiz-preview-title">
                                    Вы&nbsp;все правильно объяснили?
                                </h1>
                                <p className="quiz-preview-text">
                                    Давайте проверим, насколько хорошо вы&nbsp;обеспечиваете ребенку безопасность в&nbsp;интернете
                                </p>
                                <button
                                    className="btn__custom quiz-button quiz-button-arrow"
                                    onClick={()=>{
                                        this.props.onStartBtnClick('test-vu-vse-pravilno-obyasnili', true, '«Вы все правильно объяснили?»', '', 6)
                                    }}>
                                    Начать тест
                                </button>
                            </div>
                            <div className="quiz-preview-img">
                                <img src={test6} alt=""/>
                            </div>
                        </div>
                    </div>
                )
                break;

            case 'test-eto-normalno-ili-opasno':
                return(
                    <div className="quiz-preview quiz-bg quiz__chat quiz__test5">
                        <div className="container d-flex flex-row justify-content-between">
                            <div className="quiz-preview-description">
                                <h1 className="quiz-preview-title">
                                    Это нормально или опасно?
                                </h1>
                                <p className="quiz-preview-text">
                                    Проверь, хорошо ли&nbsp;ты&nbsp;защищаешь себя в&nbsp;интернете
                                </p>
                                <button
                                    className="btn__custom quiz-button quiz-button-arrow"
                                    onClick={()=>{
                                        this.props.onStartBtnClick('test-eto-normalno-ili-opasno', true, '«Это нормально или опасно?»', '', 5)
                                    }}>
                                    Начать тест
                                </button>
                            </div>
                            <div className="quiz-preview-img">
                                <img src={test5} alt=""/>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 'test-chto-eto-znachit':
                return (
                    <div className="quiz-preview quiz-bg quiz__chat quiz__test7">
                        <div className="container d-flex flex-row justify-content-between">
                            <div className="quiz-preview-description">
                                <h1 className="quiz-preview-title">
                                    Что это значит?
                                </h1>
                                <p className="quiz-preview-text">
                                    Уверены, что вы&nbsp;отлично пользуетесь интернетом. А&nbsp;как он&nbsp;устроен, представляете? Пройдите наш тест на&nbsp;знание &laquo;страшных&raquo; слов, связанных с&nbsp;сетью
                                </p>
                                <button
                                    className="btn__custom quiz-button quiz-button-arrow"
                                    onClick={() => {
                                        this.props.onStartBtnClick('test-chto-eto-znachit', false, '«Что это значит?»', '', 7)
                                    }}>
                                    Начать тест
                                </button>
                            </div>
                            <div className="quiz-preview-img">
                                <img src={test7Img} alt="" />
                            </div>
                        </div>
                    </div>
                )
                break;
            case 'test-chto-vy-znayete-o-memakh':
                return (
                    <div className="quiz-preview quiz-bg quiz__chat quiz__test8">
                        <div className="container d-flex flex-row justify-content-between">
                            <div className="quiz-preview-description">
                                <h1 className="quiz-preview-title">
                                    Что вы знаете о мемах?
                                </h1>
                                <p className="quiz-preview-text">
                                    Обычно мемы быстро забываются&nbsp;&mdash; но&nbsp;некоторые так хороши, что остаются смешными годы спустя. Как насчет небольшого экскурса в&nbsp;мир мемов последнего десятилетия?
                                </p>
                                <button
                                    className="btn__custom quiz-button quiz-button-arrow"
                                    onClick={() => {
                                        this.props.onStartBtnClick('test-chto-vy-znayete-o-memakh', true, '«Что вы знаете о мемах?»', '', 8)
                                    }}>
                                    Начать тест
                                </button>
                            </div>
                            <div className="quiz-preview-img">
                                <img src={test8Img} alt="" />
                            </div>
                        </div>
                    </div>
                )
                break;
        }
    }

    render() {
        return (
            <div className="quiz-list quiz-list__box">
                {
                    this.renderQuiz()
                }
            </div>
        )
    }
}
